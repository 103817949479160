import { LocalStorage } from '@services/storage';
import Axios from 'axios';

const basePath = '/api';

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'blob',
};

const http = Axios.create(defaultConfig);

http.interceptors.request.use(({ auth = true, ...config }) => {
  const AccessToken = LocalStorage.getAccessToken();

  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...(AccessToken && auth && { Authorization: `Bearer ${AccessToken}` }),
    },
  };

  return newConfig;
});

const onDownloadFile = (file) => {
  const href = URL.createObjectURL(file);

  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', 'file.txt');
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getResponsible = async (body) => {
  const data = await http.get(
    `${basePath}/export-user?date_start=${body.date_start}&date_finish=${body.date_finish}&user_id=${body.user_id}`,
  );

  onDownloadFile(data.data);
};

export const getGeneral = async (body) => {
  const data = await http.get(
    `${basePath}/export-events?date_start=${body.date_start}&date_finish=${body.date_finish}`,
  );

  onDownloadFile(data.data);
};
