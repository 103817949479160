import { useEffect, useRef, useState } from 'react';

import { CalendarSVG } from '@assets/img/icons';
import { useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import { getUserEventRequest } from '@store/ProjectAdd';
import { getResponsibleRequest } from '@store/Reports';
import { UIButton, UICalendar, UIInput, UISelect } from '@ui';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { Button } from '@utils/utility-property';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const ReportsFormResponsible = ({ className }) => {
  const calendarStartRef = useRef();
  const calendarFinishRef = useRef();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { userEvents, loading } = useSelector((state) => state.projectAdd);

  const [isCalendarStart, setCalendarStart] = useState(false);
  const [isCalendarFinish, setCalendarFinish] = useState(false);

  useHidePopupOutside(calendarStartRef, () => setCalendarStart(false));
  useHidePopupOutside(calendarFinishRef, () => setCalendarFinish(false));

  const handlerSubmit = (values) =>
    dispatch(getResponsibleRequest({ ...values, user_id: values.user_id.id }));

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handleDateClick = (date, name) => {
    form.setFieldValue(name, DateHelper.toFormat(date, FORMAT_DATE_QUERY));
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getUserEventRequest());
  }, [dispatch]);

  return (
    <form
      onSubmit={form.handleSubmit}
      className={cn('reports-general', className)}
    >
      <UISelect
        label={t('REPORT.RESPONSIBLE_PERSON')}
        name="user_id"
        options={userEvents}
        isLoading={loading}
        className="custom-select-row"
        onChange={(value) => {
          form.setFieldValue('user_id', value);
        }}
        placeholder={t('REPORT.RESPONSIBLE_PERSON')}
        isValid={!(form.errors.user_id && form.touched.user_id)}
        validateText={form.errors.user_id}
      />

      <UIInput
        label={t('COMMON.DATE_START')}
        name="date_start"
        placeholder={t('COMMON.DATE_START')}
        className="input input__row input__icon input-wrap"
        value={form.values.date_start}
        isValid={!(form.errors.date_start && form.touched.date_start)}
        validateText={form.errors.date_start}
        onFocus={() => setCalendarStart(true)}
        wrapRef={calendarStartRef}
        autoComplete="off"
      >
        <span
          className="input-icon__btn btn__close"
          onMouseDown={() => setCalendarStart(true)}
        >
          <CalendarSVG />
          {isCalendarStart && (
            <div className="rangeCalendar">
              <UICalendar
                hidePopup={() => setCalendarStart(false)}
                selectedDate={form.values.date_start}
                handleDateClick={(date) => handleDateClick(date, 'date_start')}
              />
            </div>
          )}
        </span>
      </UIInput>

      <UIInput
        label={t('COMMON.DATE_END')}
        name="date_finish"
        placeholder={t('COMMON.DATE_END')}
        className="input input__row input__icon input-wrap mb-0"
        value={form.values.date_finish}
        isValid={!(form.errors.date_finish && form.touched.date_finish)}
        validateText={form.errors.date_finish}
        onFocus={() => setCalendarFinish(true)}
        wrapRef={calendarFinishRef}
        autoComplete="off"
      >
        <span
          className="input-icon__btn btn__close"
          onMouseDown={() => setCalendarFinish(true)}
        >
          <CalendarSVG />
          {isCalendarFinish && (
            <div className="rangeCalendar">
              <UICalendar
                hidePopup={() => setCalendarFinish(false)}
                selectedDate={form.values.date_finish}
                handleDateClick={(date) => handleDateClick(date, 'date_finish')}
              />
            </div>
          )}
        </span>
      </UIInput>

      <UIButton className="btn outline__blue" type={Button.SUBMIT}>
        {t('COMMON.GENERATE')}
      </UIButton>
    </form>
  );
};

export default ReportsFormResponsible;
