import { LocalStorage } from '@services/storage';
import {
  format as fnsTzFormat,
  getTimezoneOffset,
  formatInTimeZone,
} from 'date-fns-tz';
import fnsAddHours from 'date-fns/addHours';
import fnsAddMinutes from 'date-fns/addMinutes';
import fnsAddYears from 'date-fns/addYears';
import endOfDay from 'date-fns/endOfDay';
import fnsEndOfHour from 'date-fns/endOfHour';
import endOfISOWeek from 'date-fns/endOfISOWeek';
import fnsIsSame from 'date-fns/isSameDay';
import { ru, enGB, de } from 'date-fns/locale';
import fnsParse from 'date-fns/parse';
import fnsParseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import fnsStartOfHour from 'date-fns/startOfHour';
import startOfISOWeek from 'date-fns/startOfISOWeek';

export default (() => {
  const getLocate = () => {
    switch (LocalStorage.get('language')) {
      case 'ru':
        return ru;
      case 'en':
        return enGB;
      case 'de':
        return de;

      default:
        return enGB;
    }
  };

  const isSameDay = (dateLeft, dateRight) => {
    if (dateLeft && dateRight) {
      return fnsIsSame(dateLeft, dateRight);
    }

    return false;
  };

  const parse = (date, formatString = 'yyyy-MM-dd') => {
    return typeof date === 'string'
      ? fnsParse(date, formatString, new Date())
      : date;
  };

  const parseISO = (date) => {
    return typeof date === 'string' ? fnsParseISO(date) : date;
  };

  const toFormat = (date, fmt = 'dd/MM', tz) => {
    return fnsTzFormat(parseISO(date ? new Date(date) : new Date()), fmt, {
      locale: getLocate(),
      ...(tz && {
        timeZone: tz,
      }),
    });
  };

  const toFormatTimezone = (date, fmt, tz) => {
    return formatInTimeZone(date, tz, fmt);
  };

  const addMinutes = (date, amount) => fnsAddMinutes(parseISO(date), amount);
  const addHours = (date, amount) => fnsAddHours(parseISO(date), amount);
  const addYears = (date, amount) => fnsAddYears(parseISO(date), amount);
  const startOfHour = (date) => fnsStartOfHour(parseISO(date));
  const endOfHour = (date) => fnsEndOfHour(parseISO(date));

  const startOfWeek = (date) => {
    return startOfISOWeek(
      typeof date !== 'string' ? date : new Date(date),
    ).toISOString();
  };

  const endOfWeek = (date) => {
    return endOfISOWeek(
      typeof date !== 'string' ? date : new Date(date),
    ).toISOString();
  };

  const roundingDayToUnix = (date, utc = false) => {
    const roundDateUnix = new Date(toFormat(date, 'yyyy-MM-dd')).valueOf();

    if (utc) {
      return roundDateUnix + new Date().getTimezoneOffset() * 60000;
    }

    return roundDateUnix;
  };

  const compareDate = (date1, date2, format, back = true) => {
    if (toFormat(date1, format) === toFormat(date2, format)) {
      return back ? toFormat(date1, format) : '';
    }

    return toFormat(date1, format);
  };

  return {
    isSameDay,
    parse,
    toFormat,
    addHours,
    addMinutes,
    startOfWeek,
    endOfWeek,
    startOfHour,
    endOfHour,
    toFormatTimezone,
    addYears,
    getTimezoneOffset,
    startOfDay,
    endOfDay,
    roundingDayToUnix,
    compareDate,
  };
})();
