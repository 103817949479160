import i18n from '@i18n';
import { WallHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  getListMassageFailure,
  getListMassageSuccess,
  createMassageSuccess,
  createMassageFailure,
  updateMassageSuccess,
  updateMassageFailure,
  deleteMassageSuccess,
  deleteMassageFailure,
} from './Wall.action';
import {
  GET_MASSAGES_LIST_REQUEST,
  CREATE_MASSAGE_REQUEST,
  UPDATE_MASSAGE_REQUEST,
  DELETE_MASSAGE_REQUEST,
} from './Wall.constant';

function* workerGetMassageList({ payload }) {
  try {
    const data = yield call(WallHttp.getMassageList, payload);

    yield put(
      getListMassageSuccess(
        data.sort((a, b) => new Date(b.date) - new Date(a.date)),
      ),
    );
  } catch (error) {
    yield put(getListMassageFailure());
    yield put(alertError());
  }
}

function* watchGetMassageList() {
  yield takeLatest(GET_MASSAGES_LIST_REQUEST, workerGetMassageList);
}

function* workerCreateMassage({ payload }) {
  try {
    const data = yield call(WallHttp.createMassage, payload);

    yield put(createMassageSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.MESSAGE_SENT_SUCCESSFULLY') }),
    );
  } catch (error) {
    yield put(createMassageFailure());
    yield put(alertError());
  }
}

function* watchCreateMassage() {
  yield takeLatest(CREATE_MASSAGE_REQUEST, workerCreateMassage);
}

function* workerUpdateMassage({ payload }) {
  try {
    const data = yield call(WallHttp.updateMassage, payload);

    yield put(updateMassageSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.MESSAGE_UPDATE_SUCCESSFULLY') }),
    );
  } catch (error) {
    yield put(updateMassageFailure());
    yield put(alertError());
  }
}

function* watchUpdateMassage() {
  yield takeLatest(UPDATE_MASSAGE_REQUEST, workerUpdateMassage);
}

function* workerDeleteMassage({ payload }) {
  try {
    const data = yield call(WallHttp.deleteMassage, payload);

    yield put(deleteMassageSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.MESSAGE_DELETE_SUCCESSFULLY') }),
    );
  } catch (error) {
    yield put(deleteMassageFailure());
    yield put(alertError());
  }
}

function* watchDeleteMassage() {
  yield takeLatest(DELETE_MASSAGE_REQUEST, workerDeleteMassage);
}

export const wallWatchers = [
  fork(watchGetMassageList),
  fork(watchCreateMassage),
  fork(watchUpdateMassage),
  fork(watchDeleteMassage),
];
