/* eslint-disable indent */
/* eslint-disable camelcase */
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';

import {
  // ArrLeftSVG,
  // ArrRightSVG,
  CalendarSVG,
  SearchSVG,
  SettingsSVG,
} from '@assets/img/icons';
import { useEndLessScroll, useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import { ModalType, showModal } from '@store/Modal';
import { clearTransferData, searchTransferRequest } from '@store/Transfer';
import { UIButton, UICalendar, UIInput, UILoaderWrap, UISelect } from '@ui';
import {
  FORMAT_DATE_QUERY,
  FORMAT_DAY_ALT,
  // FORMAT_DAY_MONTH_WEEK,
  FORMAT_DAY_MONTH_YEAR,
  FORMAT_MONTH,
  FORMAT_YEAR,
  UNIX_DAY,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const TransferFormList = ({
  officeLoading,
  // officeEvents,
  categories,
  groups,
  loading,
  loadingFilter,
  // isIpad,
  // isTabletMobile,
  tab,
  data,
  lastPage,
  // setTab,
}) => {
  const dispatch = useAppDispatch();

  const [isGroupStartOpen, setGroupStartOpen] = useState(false);
  const [isGroupFinishOpen, setGroupFinishOpen] = useState(false);
  // const [activeDate] = useState(new Date());
  // const [range, setRange] = useState(null);
  const [page, setPage] = useState(1);

  const groupStartRef = useRef(null);
  const groupFinishRef = useRef(null);
  const { t, i18n } = useTranslation();

  useHidePopupOutside(groupStartRef, () => setGroupStartOpen(false));
  useHidePopupOutside(groupFinishRef, () => setGroupFinishOpen(false));

  const handlerSubmit = useCallback(
    (values, page = 1) => {
      if (values) {
        const { date_start, date_finish, search, ...val } = values;

        dispatch(
          searchTransferRequest({
            page,
            filter: {
              ...val,

              ...(+tab === 1 && {
                date_between: `${DateHelper.toFormat(
                  date_start || new Date(),
                  FORMAT_DATE_QUERY,
                )},${DateHelper.toFormat(
                  date_finish || new Date('2099/01/01'),
                  FORMAT_DATE_QUERY,
                )}`,
              }),
              ...(+tab === 3 && {
                ...(date_start && { date_start }),
                date_finish:
                  date_finish ||
                  DateHelper.toFormat(
                    new Date().valueOf() - UNIX_DAY,
                    FORMAT_DATE_QUERY,
                  ),
              }),
              ...(search && {
                name: search,
              }),
            },
          }),
        );
      }
    },
    [dispatch, tab],
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    setPage(1);
    form.resetForm();
    dispatch(clearTransferData());
    handlerSubmit(form.values);

    return () => {
      form.resetForm();
      dispatch(clearTransferData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const renderHtmlEquipment = useMemo(() => {
    return (
      <div className="transfer-items">
        {data.map((item, index) => {
          return (
            <div key={index} className="transfer-item">
              <div className="transfer-item__header">
                <span className="h4 color_blue">
                  {`${DateHelper.compareDate(
                    item.date_start * 1000,
                    item.date_finish * 1000,
                    FORMAT_DAY_ALT,
                  )} ${DateHelper.compareDate(
                    item.date_start * 1000,
                    item.date_finish * 1000,
                    FORMAT_MONTH,
                    false,
                  )} ${DateHelper.compareDate(
                    item.date_start * 1000,
                    item.date_finish * 1000,
                    FORMAT_YEAR,
                    false,
                  )} - ${DateHelper.compareDate(
                    item.date_finish * 1000,
                    item.date_start * 1000,
                    FORMAT_DAY_MONTH_YEAR,
                  )}`}
                </span>
                {tab === 1 ? (
                  <UIButton
                    className="d-flex"
                    defaultClassName=""
                    handler={() =>
                      dispatch(
                        showModal({
                          modalType: ModalType.TRANSFER__SETTINGS,
                          modalProps: item,
                        }),
                      )
                    }
                  >
                    <SettingsSVG />
                  </UIButton>
                ) : null}
              </div>
              <div className="transfer-item__title h3">
                {item?.equipment?.name}
              </div>
              <div className="transfer-item__info">
                <span className="h5 fw600 color_grey">
                  <span className="color_blue">{t('COMMON.CATEGORY')}: </span>
                  {item.equipment?.category?.name}
                </span>
                {item.office?.name ? (
                  <span className="h5 fw600 color_grey">
                    <span className="color_blue">{t('COMMON.OFFICE')}: </span>
                    {item.office?.name}
                  </span>
                ) : null}
              </div>
              <div className="transfer-item__info">
                <span className="h5 fw600 color_grey">
                  <span className="color_blue">
                    {t('COMMON.FROM_WAREHOUSE')}:{' '}
                  </span>
                  {item?.group_at?.name}
                </span>
                <span className="h5 fw600 color_grey">
                  <span className="color_blue">
                    {t('COMMON.TO_WAREHOUSE')}:{' '}
                  </span>
                  {item?.group_to?.name}
                </span>
                <span className="h5 fw600 color_grey">
                  <span className="color_blue">{t('COMMON.QUANTITY')}: </span>
                  {item.count}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [data, dispatch, t, tab]);

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (page < lastPage && data.length) {
        setPage(+page + 1);
        handlerSubmit(form.values, +page + 1);
      }
    },
  });

  const handlerOnChange = (value, name) => {
    setPage(1);
    form.setFieldValue(name, value);
    if (
      JSON.stringify(form.values) !==
      JSON.stringify({ ...form.values, [name]: value })
    ) {
      dispatch(clearTransferData());
      handlerSubmit({ ...form.values, [name]: value });
    }
  };

  const handleDateClick = (date, name) => {
    form.setFieldValue(name, DateHelper.toFormat(date, FORMAT_DATE_QUERY));
  };

  // useEffect(() => {
  //   if (isIpad || isTabletMobile) {
  //     const newDate =
  //       +tab === 1 ? new Date() : new Date(new Date().valueOf() - UNIX_DAY);

  //     setActiveDate(newDate);
  //     handlerOnChange(
  //       DateHelper.toFormat(new Date(newDate - UNIX_DAY), FORMAT_DATE_QUERY),
  //       'date_start',
  //     );
  //     handlerOnChange(
  //       DateHelper.toFormat(new Date(newDate), FORMAT_DATE_QUERY),
  //       'date_finish',
  //     );
  //   } else {
  //     handlerOnChange('', 'date_start');
  //     handlerOnChange('', 'date_finish');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isIpad, isTabletMobile]);

  return (
    <>
      <div className="transfer-left">
        <form onSubmit={form.handleSubmit}>
          <UIInput
            label={t('COMMON.DATE_START')}
            name="date_start"
            placeholder={t('TRANSFER.SELECT_START_DATE')}
            className="input input__row input__icon"
            classLabel="h6 fw600"
            value={form.values.date_start}
            islabelRow
            isValid={!(form.errors.date_start && form.touched.date_start)}
            validateText={form.errors.date_start}
            onFocus={() => setGroupStartOpen(true)}
            wrapRef={groupStartRef}
            autoComplete="off"
          >
            <span
              className="input-icon__btn input-icon__calendar"
              onMouseDown={() => setGroupStartOpen(true)}
            >
              <CalendarSVG />
              {isGroupStartOpen && (
                <div className="rangeCalendar">
                  <UICalendar
                    hidePopup={() => setGroupStartOpen(false)}
                    maxDate={form.values?.date_finish}
                    selectedDate={form.values.date_start}
                    handleDateClick={(date) =>
                      handleDateClick(date, 'date_start')
                    }
                  />
                </div>
              )}
            </span>
          </UIInput>

          <UIInput
            label={t('COMMON.DATE_END')}
            name="date_finish"
            placeholder={t('TRANSFER.SELECT_END_DATE')}
            className="input input__row input__icon"
            classLabel="h6 fw600"
            value={form.values.date_finish}
            islabelRow
            isValid={!(form.errors.date_finish && form.touched.date_finish)}
            validateText={form.errors.date_finish}
            onFocus={() => setGroupFinishOpen(true)}
            wrapRef={groupFinishRef}
            autoComplete="off"
          >
            <span
              className="input-icon__btn input-icon__calendar"
              onMouseDown={() => setGroupFinishOpen(true)}
            >
              <CalendarSVG />
              {isGroupFinishOpen && (
                <div className="rangeCalendar">
                  <UICalendar
                    minDate={
                      +tab === 1 &&
                      (form.values?.date_start !== '' &&
                      new Date().valueOf() <
                        new Date(form.values?.date_start).valueOf()
                        ? form.values?.date_start
                        : new Date())
                    }
                    maxDate={+tab === 3 && new Date().valueOf() - UNIX_DAY}
                    hidePopup={() => setGroupFinishOpen(false)}
                    selectedDate={form.values.date_finish}
                    handleDateClick={(date) =>
                      handleDateClick(date, 'date_finish')
                    }
                  />
                </div>
              )}
            </span>
          </UIInput>

          <UISelect
            name="group_at"
            loading={loading}
            className="select-wrap custom-select-row"
            label={t('COMMON.FROM_WAREHOUSE')}
            classLabel="h6 fw600"
            options={groups}
            placeholder={t('COMMON.WAREHOUSE')}
            value={groups.find((i) => +i.id === +form.values.group_at)}
            isValid={!(form.errors.group_at && form.touched.group_at)}
            validateText={form.errors.group_at}
            onChange={(value) => handlerOnChange(value?.id || null, 'group_at')}
          />

          <UISelect
            name="group_from"
            loading={loading}
            className="select-wrap custom-select-row"
            label={t('COMMON.TO_WAREHOUSE')}
            classLabel="h6 fw600"
            options={groups}
            placeholder={t('COMMON.WAREHOUSE')}
            value={groups.find((i) => +i.id === +form.values.group_from)}
            isValid={!(form.errors.group_from && form.touched.group_from)}
            validateText={form.errors.group_from}
            onChange={(value) =>
              handlerOnChange(value?.id || null, 'group_from')
            }
          />

          <UISelect
            label={t('COMMON.CATEGORY')}
            className="select-wrap custom-select-row"
            options={categories}
            name="category_id"
            loading={loading}
            classLabel="h6 fw600"
            placeholder={t('COMMON.CATEGORY')}
            value={categories.find((i) => +i.id === +form.values.category_id)}
            onChange={(value) =>
              handlerOnChange(value?.id || null, 'category_id')
            }
            isValid={!(form.errors.category_id && form.touched.category_id)}
            validateText={form.errors.category_id}
          />

          {/* <UISelect
            label="Офис"
            className="select-wrap custom-select-row"
            options={officeEvents}
            name="office_id"
            loading={officeLoading}
            classLabel="h6 fw600"
            placeholder="Офис"
            value={officeEvents.find((i) => +i.id === +form.values.office_id)}
            onChange={(value) =>
              handlerOnChange(value?.id || null, 'office_id')
            }
            isValid={!(form.errors.office_id && form.touched.office_id)}
            validateText={form.errors.office_id}
          /> */}

          <UIInput
            value={form.values.search}
            name="search"
            className="input input__row input__icon"
            classLabel="h6 fw600"
            label={t('TRANSFER.NAME_EQUIPMENT')}
            islabelRow
            placeholder={t('COMMON.SEARCH')}
            onChange={(e) => handlerOnChange(e.target.value, 'search')}
          >
            <span className="input-icon__btn">
              <SearchSVG />
            </span>
          </UIInput>
        </form>
      </div>
      {/* {isTabletMobile ? (
        <div className="transfer-calendar_mobile hide-desktop show-mobile-flex">
          <div>
            {(+tab === 1 && activeDate.valueOf() >= new Date().valueOf()) ||
            +tab === 3 ? (
              <UIButton
                handler={() => {
                  setActiveDate(new Date(activeDate.valueOf() - UNIX_DAY));
                  handlerOnChange(
                    DateHelper.toFormat(
                      new Date(activeDate.valueOf() - UNIX_DAY),
                      FORMAT_DATE_QUERY,
                    ),
                    'date_start',
                  );
                  handlerOnChange(
                    DateHelper.toFormat(
                      new Date(activeDate.valueOf()),
                      FORMAT_DATE_QUERY,
                    ),
                    'date_finish',
                  );
                }}
                defaultClassName=""
              >
                <ArrLeftSVG />
              </UIButton>
            ) : null}
            <CalendarSVG />
            <span
              onClick={() =>
                dispatch(
                  showModal({
                    modalType: ModalType.MOBILE__DATE,
                    modalProps: {
                      range,
                      setRange,
                    },
                  }),
                )
              }
            >
              {DateHelper.toFormat(activeDate, FORMAT_DAY_MONTH_WEEK)}
            </span>
            {+tab === 1 ||
            (+tab === 3 &&
              activeDate.valueOf() + UNIX_DAY * 2 < new Date().valueOf()) ? (
              <UIButton
                handler={() => {
                  setActiveDate(new Date(activeDate.valueOf() + UNIX_DAY));
                  handlerOnChange(
                    DateHelper.toFormat(
                      new Date(activeDate.valueOf() + UNIX_DAY),
                      FORMAT_DATE_QUERY,
                    ),
                    'date_start',
                  );
                  handlerOnChange(
                    DateHelper.toFormat(
                      new Date(activeDate.valueOf() + UNIX_DAY * 2),
                      FORMAT_DATE_QUERY,
                    ),
                    'date_finish',
                  );
                }}
                defaultClassName=""
              >
                <ArrRightSVG />
              </UIButton>
            ) : null}
          </div>
        </div>
      ) : null} */}

      <div onScroll={onScroll} ref={containerRef} className="transfer-right">
        <UILoaderWrap loading={officeLoading || loading || loadingFilter}>
          {renderHtmlEquipment}
        </UILoaderWrap>
      </div>

      {/* {isIpad || isTabletMobile ? (
        <div className="transfer-calendar">
          <UICalendar
            selectedDate={activeDate}
            {...(+tab === 3 && {
              maxDate: new Date(new Date().valueOf() - UNIX_DAY),
            })}
            {...(+tab === 1 && {
              minDate: new Date(),
            })}
            handleDateClick={(date) => {
              handlerOnChange(
                DateHelper.toFormat(
                  new Date(date.valueOf()),
                  FORMAT_DATE_QUERY,
                ),
                'date_start',
              );
              handlerOnChange(
                DateHelper.toFormat(
                  new Date(date.valueOf() + UNIX_DAY),
                  FORMAT_DATE_QUERY,
                ),
                'date_finish',
              );
            }}
          >
            <UIButton
              className="btn outline__blue"
              type={Button.BUTTON}
              disabled={+tab === 3}
              handler={() => setTab(3)}
            >
              {t('TRANSFER.ALL_EVENST')}
            </UIButton>
          </UICalendar>
        </div>
      ) : null} */}
    </>
  );
};

export default TransferFormList;
