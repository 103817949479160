export const GET_MASSAGES_LIST_REQUEST = '@wall/GET_MASSAGES_LIST_REQUEST';
export const GET_MASSAGES_LIST_SUCCESS = '@wall/GET_MASSAGES_LIST_SUCCESS';
export const GET_MASSAGES_LIST_FAILURE = '@wall/GET_MASSAGES_LIST_FAILURE';

export const CREATE_MASSAGE_REQUEST = '@wall/CREATE_MASSAGE_REQUEST';
export const CREATE_MASSAGE_SUCCESS = '@wall/CREATE_MASSAGE_SUCCESS';
export const CREATE_MASSAGE_FAILURE = '@wall/CREATE_MASSAGE_FAILURE';

export const UPDATE_MASSAGE_REQUEST = '@wall/UPDATE_MASSAGE_REQUEST';
export const UPDATE_MASSAGE_SUCCESS = '@wall/UPDATE_MASSAGE_SUCCESS';
export const UPDATE_MASSAGE_FAILURE = '@wall/UPDATE_MASSAGE_FAILURE';

export const DELETE_MASSAGE_REQUEST = '@wall/DELETE_MASSAGE_REQUEST';
export const DELETE_MASSAGE_SUCCESS = '@wall/DELETE_MASSAGE_SUCCESS';
export const DELETE_MASSAGE_FAILURE = '@wall/DELETE_MASSAGE_FAILURE';
