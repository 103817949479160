/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { useHideModalOutside, useResponsive } from '@hooks';
import { addOneEventInfo, getOneEventRequest } from '@store/Calendar';
import { useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';
import { UIButton, UILoaderWrap } from '@ui';
import { FORMAT_DATE_TEXT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { LINK_PROJECTS } from '@utils/link';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './styles.scss';

const WidgetsModalCalendarEvent = ({ pageX, pageY, clientY, event }) => {
  const { oneEvent, loadingModal } = useSelector((state) => state.calendar);
  const [popupPosition, setPopupPosition] = useState({
    left: `${pageX}px`,
    top: `${pageY}px`,
    transform: 'translateY(-0%)',
    opacity: '0',
  });

  const dispatch = useAppDispatch();
  const { isMobile } = useResponsive();
  const popupRef = useHideModalOutside(() => dispatch(hideModal()));
  const { t } = useTranslation();

  useEffect(() => {
    const translateY = (clientY / window.innerHeight) * 100;

    setPopupPosition({
      left: isMobile
        ? 'calc(50% - 181px)'
        : `${window.innerWidth / 2 < pageX ? pageX - 363 : pageX}px`,
      top: `${pageY}px`,
      transform: `translateY(-${translateY}0%)`,
      opacity: '1',
    });
    dispatch(getOneEventRequest(event.id));
    dispatch(
      addOneEventInfo({
        title: event.title,
        id: event.id,
      }),
    );
  }, [event, pageX, pageY, clientY, dispatch, isMobile]);

  const handlerHideModal = () => {
    dispatch(hideModal());
  };

  return (
    <div ref={popupRef} style={popupPosition} className="fullCalendar__popup">
      <div className="popup__close" onClick={handlerHideModal} />
      <div className="color_dark h3 fw600">
        {loadingModal
          ? 'Loading...'
          : `${DateHelper.toFormat(
              oneEvent.date_start_event * 1000,
              FORMAT_DATE_TEXT,
            )} - ${DateHelper.toFormat(
              (oneEvent.date_finish_event || oneEvent.date_start_event) * 1000,
              FORMAT_DATE_TEXT,
            )}`}
      </div>
      <UIButton
        type={Button.LINK}
        link={`${LINK_PROJECTS}/${oneEvent?.id}`}
        handler={() => dispatch(hideModal())}
        className="fullCalendar-popup__title color_darkBlue h6"
      >
        {oneEvent?.title}
      </UIButton>
      <div className="fullCalendar-popup__items">
        <UILoaderWrap bgTransparent={true} loading={loadingModal}>
          {oneEvent?.reservations?.map((item, index) => (
            <div key={index} className="fullCalendar-popup__item">
              <div className="fullCalendar-popupItem__title fw600">
                <span style={{ background: '#00BA34' }} />
                <div style={{ color: '#00BA34' }}>{item.name}</div>
              </div>
              {item ? (
                <div className="fullCalendar-popupItem__dataStar h6">
                  {t('CALENDAR.DATE')}
                  <span className="color_grey">
                    {
                      // eslint-disable-next-line prefer-template
                      DateHelper.toFormat(
                        item?.start * 1000,
                        FORMAT_DATE_TEXT,
                      ) +
                        ' - ' +
                        DateHelper.toFormat(
                          item?.finish * 1000,
                          FORMAT_DATE_TEXT,
                        )
                    }
                  </span>
                </div>
              ) : null}
              <div className="fullCalendar-popupItem__dataEnd h6">
                {t('PROJECT.LOADING_DATES')}
                <span className="color_grey">
                  {DateHelper.toFormat(item?.loading * 1000, FORMAT_DATE_TEXT)}
                </span>
              </div>
            </div>
          ))}
        </UILoaderWrap>
      </div>
    </div>
  );
};

export default WidgetsModalCalendarEvent;
