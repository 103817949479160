/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';

import { BasketSVG, CalendarSVG } from '@assets/img/icons';
import { useHidePopupOutside } from '@hooks';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import {
  deleteTransferRequest,
  validationTransferRequest,
} from '@store/Transfer';
import { UIButton, UICalendar, UIInput, UIInputNumber, UISelect } from '@ui';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './style.scss';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const WidgetsModalTransferSettings = (props) => {
  const { loading, categories, groups } = useSelector(
    (state) => state.categories,
  );
  const { officeEvents, loading: officeLoading } = useSelector(
    (state) => state.projectAdd,
  );
  const { loadingChange } = useSelector((state) => state.transfer);

  const [isGroupStartOpen, setGroupStartOpen] = useState(false);
  const [isGroupFinishOpen, setGroupFinishOpen] = useState(false);

  const groupStartRef = useRef(null);
  const groupFinishRef = useRef(null);

  useHidePopupOutside(groupStartRef, () => setGroupStartOpen(false));
  useHidePopupOutside(groupFinishRef, () => setGroupFinishOpen(false));

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = ({
    isTransfered,
    date_start,
    date_finish,
    office,
    ...val
  }) => {
    dispatch(
      validationTransferRequest({
        ...val,
        date_start: `${date_start} 00:00:00`,
        date_finish: `${date_finish} 00:00:00`,
        count: isTransfered,
        office_id: 2,
        // office_id: office?.id,
      }),
    );
    dispatch(hideModal());
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALUES,
      date_start: DateHelper.toFormat(
        props.date_start * 1000,
        FORMAT_DATE_QUERY,
      ),
      date_finish: DateHelper.toFormat(
        props.date_finish * 1000,
        FORMAT_DATE_QUERY,
      ),
      equipment_id: props.equipment?.id,
      name: props.equipment?.name,
      category_id: props.equipment.category?.id,
      group_for: props.group_to?.id,
      group_at: props.group_at?.id,
      office_id: props.office?.id,
      isTransfered: props.count,
    },
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handleDateClick = (date, name) => {
    form.setFieldValue(name, DateHelper.toFormat(date, FORMAT_DATE_QUERY));
  };

  const handlerDelete = () => {
    dispatch(
      deleteTransferRequest({
        transferId: props.id,
        equipmentId: props.equipment.id,
      }),
    );
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal title={t('COMMON.SETTINGS')} className="modal__transfer">
      <form onSubmit={form.handleSubmit}>
        <UIInput
          label={t('COMMON.DATE_END')}
          name="date_finish"
          placeholder={t('COMMON.DATE_END')}
          classLabel="fw600"
          className="input input__icon"
          value={form.values.date_finish}
          isValid={!(form.errors.date_finish && form.touched.date_finish)}
          validateText={form.errors.date_finish}
          onFocus={() => setGroupFinishOpen(true)}
          wrapRef={groupFinishRef}
          autoComplete="off"
        >
          <span
            className="input-icon__btn input-icon__calendar"
            onMouseDown={() => setGroupFinishOpen(true)}
          >
            <CalendarSVG />
            {isGroupFinishOpen && (
              <div className="rangeCalendar">
                <UICalendar
                  hidePopup={() => setGroupFinishOpen(false)}
                  selectedDate={form.values.date_finish}
                  handleDateClick={(date) =>
                    handleDateClick(date, 'date_finish')
                  }
                />
              </div>
            )}
          </span>
        </UIInput>
        <UIInput
          name="name"
          disabled={true}
          id="name_modal2"
          label={t('TRANSFER.NAME_EQUIPMENT')}
          classLabel="fw600"
          placeholder={t('COMMON.TITLE')}
          value={form.values?.name}
          // onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />
        <UIInput
          disabled={true}
          label={t('COMMON.DATE_START')}
          name="date_start"
          placeholder={t('COMMON.DATE_START')}
          className="input input__icon"
          classLabel="fw600"
          value={form.values.date_start}
          isValid={!(form.errors.date_start && form.touched.date_start)}
          validateText={form.errors.date_start}
          // onFocus={() => setGroupStartOpen(true)}
          wrapRef={groupStartRef}
          autoComplete="off"
        >
          <span
            className="input-icon__btn input-icon__calendar"
            // onMouseDown={() => setGroupStartOpen(true)}
          >
            <CalendarSVG />
            {isGroupStartOpen && (
              <div className="rangeCalendar">
                <UICalendar
                  // hidePopup={() => setGroupStartOpen(false)}
                  selectedDate={form.values.date_start}
                  handleDateClick={(date) =>
                    handleDateClick(date, 'date_start')
                  }
                />
              </div>
            )}
          </span>
        </UIInput>
        <UISelect
          name="category_id"
          disabled={true}
          loading={loading}
          label={t('COMMON.CATEGORY')}
          classLabel="fw600"
          options={categories}
          placeholder={t('COMMON.CATEGORY')}
          value={categories.find((i) => +i.id === +form.values.category_id)}
          // onChange={(value) => form.setFieldValue('category_id', value.id || null)}
          isValid={!(form.errors.category && form.touched.category)}
          validateText={form.errors.category}
        />

        <UISelect
          name="group_at"
          loading={loading}
          disabled={true}
          className="select-wrap"
          label={t('COMMON.FROM_WAREHOUSE')}
          classLabel="h6 fw600"
          options={groups}
          placeholder={t('COMMON.WAREHOUSE')}
          value={groups.find((i) => +i.id === +form.values.group_at)}
          // onChange={(value) => form.setFieldValue('group_at', value.id || null)}
          isValid={!(form.errors.group_at && form.touched.group_at)}
          validateText={form.errors.group_at}
        />
        <UISelect
          name="group_for"
          loading={loading}
          disabled={true}
          className="select-wrap"
          label={t('COMMON.TO_WAREHOUSE')}
          classLabel="h6 fw600"
          options={groups}
          placeholder={t('COMMON.WAREHOUSE')}
          value={groups.find((i) => +i.id === +form.values.group_for)}
          // onChange={(value) => form.setFieldValue('group_for', value.id || null)}
          isValid={!(form.errors.group_for && form.touched.group_for)}
          validateText={form.errors.group_for}
        />
        <UISelect
          label={t('COMMON.OFFICE')}
          disabled={true}
          className="select-wrap"
          options={officeEvents}
          name="office_id"
          loading={officeLoading}
          classLabel="h6 fw600"
          placeholder={t('COMMON.OFFICE')}
          value={officeEvents.find((i) => +i.id === +form.values.office_id)}
          // onChange={(value) => {
          //   form.setFieldValue('office', +value?.id || '');
          // }}
          isValid={!(form.errors.office_id && form.touched.office_id)}
          validateText={form.errors.office_id}
        />
        <UIInputNumber
          min={0}
          disabled={true}
          name="isTransfered"
          label={t('COMMON.QUANTITY_MIN')}
          classLabel="h6 fw600"
          value={form.values?.isTransfered}
          // onChange={(value) => form.setFieldValue('isTransfered', value)}
          isValid={!(form.errors.isTransfered && form.touched.isTransfered)}
          validateText={form.errors.isTransfered}
        />
        <div className="added-btn">
          {/* <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            <span>{t('COMMON.SAVE')}</span>
          </UIButton> */}
          <UIButton
            handler={() => dispatch(hideModal())}
            className="btn btn__gray"
          >
            {t('COMMON.CANCEL')}
          </UIButton>
        </div>
        <div className="transfer-delete">
          <UIButton
            loading={loadingChange}
            className="btn btn__delete"
            handler={handlerDelete}
            type={Button.BUTTON}
          >
            <span>
              <BasketSVG />
            </span>
            {t('TRANSFER.DELETE_TRANSFER')}
          </UIButton>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalTransferSettings;
