/* eslint-disable indent */
import { memo, useRef, useState } from 'react';

import { CalendarSVG } from '@assets/img/icons';
import { useHidePopupOutside, useResponsive } from '@hooks';
import { useAppDispatch } from '@store/';
import { alertError } from '@store/Alert';
import { ModalType, showModal } from '@store/Modal';
import { UICalendar, UIInput, UIRangeCalendar, UITimePicker } from '@ui';
import {
  FORMAT_DATE_QUERY,
  FORMAT_DATE_QUERY_WITH_TIME,
} from '@utils/constants';
import { ArrayHelper, DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

import circleCloseIcon from '../../../../assets/img/icons/circle_close-grey.svg';
import ProjectAddMainBookingHeader from './Header';
import ProjectAddManagerBookingTable from './Table';

const ProjectAddManagerBooking = ({
  form,
  transportList,
  userList,
  loadingTransport,
  loadingUser,
  start,
  finish,
  editProject,
  setIdxBookingMenu,
  idxBookingMenu,
}) => {
  const [isRangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const [idxBookingContent, setIdxBookingContent] = useState(null);
  const [range, setRange] = useState({
    endDate: new Date(),
    key: 'selection',
    startDate: new Date(),
  });

  const calendarRef = useRef(null);
  const calendarRangeRef = useRef(null);
  const dispatch = useAppDispatch();
  const { isMobile, isIpad, isTablet } = useResponsive();
  const { t } = useTranslation();

  useHidePopupOutside(calendarRangeRef, () => setRangeCalendarOpen(false));
  useHidePopupOutside(calendarRef, () => setCalendarOpen(false));

  const { reservations } = form.values;

  const handlerSelect = (ranges) => {
    const { startDate, endDate } = ranges?.selection;

    if (startDate && endDate) {
      const newReservations = [...reservations];

      newReservations[idxBookingMenu] = {
        ...reservations[idxBookingMenu],
        finish: DateHelper.toFormat(endDate, FORMAT_DATE_QUERY_WITH_TIME),
        start: DateHelper.toFormat(startDate, FORMAT_DATE_QUERY_WITH_TIME),
        dateBooking: DateHelper.toFormat(endDate, FORMAT_DATE_QUERY_WITH_TIME),
      };

      form.setFieldValue('reservations', newReservations);
      setRange({
        startDate,
        endDate,
        key: 'selection',
      });
    }
  };

  const handleDateClick = (date, index) => {
    form.setFieldValue(
      'reservations',
      ArrayHelper.overwriteArray(
        reservations,
        index,
        DateHelper.toFormat(date, FORMAT_DATE_QUERY),
        'loading',
      ),
    );
  };

  const deleteBooking = (index) => {
    form.setFieldValue(
      'reservations',
      reservations.filter((i, idx) => idx !== index),
    );
    setIdxBookingMenu((prev) =>
      index === 0 ? prev + (reservations.length === 2 ? 0 : 1) : prev - 1,
    );
  };

  const openDeleteModal = (index) => {
    dispatch(
      showModal({
        modalType: ModalType.DELETE_BOOKING,
        modalProps: {
          handler: () => deleteBooking(index),
          title: reservations[index].name,
        },
      }),
    );
  };

  return (
    <>
      <ProjectAddMainBookingHeader
        setReservations={form.setFieldValue}
        reservations={reservations}
        setIdxBookingMenu={setIdxBookingMenu}
        idxBookingContent={idxBookingContent}
        openModal={openDeleteModal}
        setIdxBookingContent={setIdxBookingContent}
      />
      {form.values.reservations.map((item, index) => {
        if (index === idxBookingMenu) {
          const startDate = form.values.reservations[index]?.start;
          const finishDate = form.values.reservations[index]?.finish;
          const isEditReservations =
            editProject &&
            new Date(item?.finish) <
              new Date(DateHelper.roundingDayToUnix(new Date(), true));
          const disabledText = t('PROJECT.THIS_BOOKING_ALREADY_OVER');

          const errorBook = form.errors.reservations;
          const touchedBook = form.touched.reservations;

          if (idxBookingContent !== index) {
            setIdxBookingContent(index);
          }

          return (
            <div key={index} className="addEvent-booking__tab">
              <div className="addEvent-booking__title h4 fw600 hide-desktop show-mobile">
                {item.name}

                {index !== idxBookingMenu ||
                  (reservations.length > 1 && (
                    <img
                      onClick={() => openDeleteModal(index)}
                      src={circleCloseIcon}
                      alt="icon"
                    />
                  ))}
              </div>
              <div className="addEvent-tab__wrap">
                <UIInput
                  label={t('PROJECT.BOOKING_NAME')}
                  name="name"
                  disabled={isEditReservations}
                  value={form.values.reservations[index]?.name}
                  className="input input__row input-wrap"
                  placeholder={t('PROJECT.BOOKING_NAME')}
                  classLabel="fw600"
                  onChange={(e) => {
                    const { value } = e.target;

                    form.setFieldValue(
                      'reservations',
                      ArrayHelper.overwriteArray(
                        reservations,
                        index,
                        value,
                        'name',
                      ),
                    );
                  }}
                  islabelRow
                  isValid={
                    !(errorBook?.[index]?.name && touchedBook?.[index]?.name)
                  }
                  validateText={errorBook?.[index]?.name?.split('.')[1]}
                />
                <UIInput
                  label={t('PROJECT.BOOKING_DATES')}
                  disabled={isEditReservations}
                  placeholder={`${t('COMMON.START_DATE')} \u{2192} ${t(
                    'COMMON.END_DATE',
                  )}`}
                  className="input input__row input__icon"
                  classLabel="fw600 icon_necessarily"
                  name="dateBooking"
                  value={
                    startDate && finishDate
                      ? `${DateHelper.toFormat(
                          startDate,
                          FORMAT_DATE_QUERY,
                        )} - ${DateHelper.toFormat(
                          finishDate,
                          FORMAT_DATE_QUERY,
                        )}`
                      : ''
                  }
                  islabelRow
                  isValid={
                    !(
                      errorBook?.[index]?.dateBooking &&
                      touchedBook?.[index]?.dateBooking
                    )
                  }
                  validateText={errorBook?.[index]?.dateBooking?.split('.')[1]}
                  onFocus={() =>
                    isEditReservations
                      ? dispatch(alertError(disabledText))
                      : setRangeCalendarOpen(true)
                  }
                  wrapRef={calendarRangeRef}
                  autoComplete="off"
                >
                  <span
                    className="input-icon__btn input-icon__calendar"
                    onClick={() =>
                      isEditReservations
                        ? dispatch(alertError(disabledText))
                        : setRangeCalendarOpen(true)
                    }
                  >
                    <CalendarSVG />
                    {isRangeCalendarOpen && (
                      <div
                        style={
                          isMobile || isIpad || isTablet ? { right: 0 } : {}
                        }
                        className="rangeCalendar"
                      >
                        <UIRangeCalendar
                          minDate={
                            editProject ? new Date() : new Date(start * 1000)
                          }
                          maxDate={new Date(finish * 1000)}
                          range={range}
                          handlerSelect={(date) => handlerSelect(date, index)}
                          showMonthAndYearPickers={false}
                        />
                      </div>
                    )}
                  </span>
                </UIInput>
                <div className="addEvent-tab__row">
                  <UIInput
                    label={t('PROJECT.LOADING_DATE')}
                    name="loading"
                    disabled={isEditReservations}
                    placeholder={t('PROJECT.LOADING_DATE')}
                    className="input input__row input-wrap input__icon"
                    value={
                      form.values.reservations[index]?.loading ||
                      form.values.reservations[index]?.time
                        ? `${form.values.reservations[index]?.loading} ${form.values.reservations[index]?.time}`
                        : ''
                    }
                    islabelRow
                    isValid={
                      !(
                        errorBook?.[index]?.loading &&
                        touchedBook?.[index]?.loading
                      )
                    }
                    validateText={errorBook?.[index]?.loading?.split('.')[1]}
                    onFocus={() =>
                      isEditReservations
                        ? dispatch(alertError(disabledText))
                        : setCalendarOpen(true)
                    }
                    wrapRef={calendarRef}
                    autoComplete="off"
                  >
                    <span
                      className="input-icon__btn input-icon__calendar"
                      onMouseDown={() =>
                        isEditReservations
                          ? dispatch(alertError(disabledText))
                          : setCalendarOpen(true)
                      }
                    >
                      <CalendarSVG />
                      {isCalendarOpen && (
                        <div
                          style={
                            isMobile || isIpad || isTablet ? { right: 0 } : {}
                          }
                          className="rangeCalendar"
                        >
                          <UICalendar
                            hidePopup={() => setCalendarOpen(false)}
                            maxDate={finish * 1000}
                            minDate={editProject ? new Date() : start * 1000}
                            handleDateClick={(date) =>
                              handleDateClick(date, index)
                            }
                          />
                        </div>
                      )}
                    </span>
                  </UIInput>
                  <div className="select__row">
                    <UITimePicker
                      label={t('COMMON.TIME')}
                      name="time"
                      handlerDisabled={() => dispatch(alertError(disabledText))}
                      disabled={isEditReservations}
                      isValid={
                        !(
                          errorBook?.[index]?.time && touchedBook?.[index]?.time
                        )
                      }
                      value={form.values.reservations[index].time}
                      validateText={
                        errorBook?.[index]?.time?.split('.')[1] || ''
                      }
                      autoComplete="off"
                      onChange={(value) => {
                        form.setFieldValue(
                          'reservations',
                          ArrayHelper.overwriteArray(
                            reservations,
                            index,
                            value || '',
                            'time',
                          ),
                        );
                      }}
                    />
                  </div>
                </div>
                <ProjectAddManagerBookingTable
                  title={t('PROJECT.TRANSPORT')}
                  disabled={isEditReservations}
                  disabledText={disabledText}
                  list={transportList}
                  loading={loadingTransport}
                  form={form}
                  index={idxBookingMenu}
                  isTransport
                />
                <ProjectAddManagerBookingTable
                  title={t('PROJECT.STAFF')}
                  disabled={isEditReservations}
                  disabledText={disabledText}
                  list={userList}
                  loading={loadingUser}
                  form={form}
                  index={idxBookingMenu}
                />
              </div>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export default memo(ProjectAddManagerBooking);
