/* eslint-disable no-return-assign */
/* eslint-disable indent */
import { Fragment, useCallback } from 'react';

import { UILoaderWrap } from '@ui';
import { FORMAT_MONTH } from '@utils/constants';
import { DateHelper } from '@utils/helpers';

import WallItem from '../Item';

const WallList = ({ massageList, loading }) => {
  const renderList = useCallback(() => {
    let month = '';

    return massageList?.map((massage, index) => {
      return (
        <Fragment key={index}>
          {month !== DateHelper.toFormat(massage.date, FORMAT_MONTH)
            ? (month = DateHelper.toFormat(massage.date, FORMAT_MONTH)) && (
                <span className="wall-chat__month">{month}</span>
              )
            : null}

          <WallItem massage={massage} />
        </Fragment>
      );
    });
  }, [massageList]);

  return (
    <UILoaderWrap loading={loading}>
      <div className="wall-chat__items">{renderList()}</div>
    </UILoaderWrap>
  );
};

export default WallList;
