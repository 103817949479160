import { useMemo } from 'react';

import FullCalendar from '@fullcalendar/react';
// eslint-disable-next-line import/order
import dayGridPlugin from '@fullcalendar/daygrid';
import { LocalStorage } from '@services/storage';
import { FORMAT_DATE_QUERY, UNIX_DAY } from '@utils/constants';
import { DateHelper, NumberHelper } from '@utils/helpers';

const CalendarsYear = ({ e, events, handleDateClick }) => {
  const renderHtml = useMemo(() => {
    const dataYear = [];
    let eventsYear = {};
    const rangeStart = DateHelper.toFormat(
      e.dateProfile.currentRange.start,
      FORMAT_DATE_QUERY,
    );
    const rangeEnd = DateHelper.toFormat(
      new Date(e.dateProfile.currentRange.end).valueOf() - UNIX_DAY,
      FORMAT_DATE_QUERY,
    );

    for (let i = 1; i <= 12; i++) {
      dataYear.push(
        `${e.dateProfile.currentRange.start.getFullYear()}-${`0${i}`.slice(
          -2,
        )}-01`,
      );
    }

    const buildYearEvent = (start, end, id) => {
      eventsYear = {
        ...eventsYear,
        [start]: eventsYear[start] ? [...eventsYear[start], id] : [id],
      };

      const newStart = DateHelper.toFormat(
        new Date(start).valueOf() + UNIX_DAY,
        FORMAT_DATE_QUERY,
      );

      if (newStart !== end) {
        buildYearEvent(newStart, end, id);
      }

      return null;
    };

    events?.forEach((item) => {
      const start =
        new Date(item.start) < new Date(rangeStart)
          ? rangeStart
          : DateHelper.toFormat(item.start, FORMAT_DATE_QUERY);
      const end =
        new Date(item.end) > new Date(rangeEnd)
          ? rangeEnd
          : DateHelper.toFormat(item.end, FORMAT_DATE_QUERY);

      if (start !== end && new Date(start) < new Date(end)) {
        buildYearEvent(start, end, item.id);
      }
    });

    return dataYear.map((i, index) => (
      <div key={index} className="calendarYear-wrap">
        <FullCalendar
          plugins={[dayGridPlugin]}
          headerToolbar={{
            left: '',
            center: 'title',
            right: '',
          }}
          locale={LocalStorage.get('language')}
          firstDay={1}
          titleFormat={{ month: 'long' }}
          initialView="dayGridMonth"
          showNonCurrentDates={false}
          initialDate={i}
          height="auto"
          dayCellContent={(e) => (
            <div
              onClick={() => handleDateClick(e.date)}
              className={`calendarYear-more h6 fw600 calendarYear-more__${NumberHelper.roundingNearest(
                eventsYear[DateHelper.toFormat(e.date, FORMAT_DATE_QUERY)]
                  ?.length,
                5,
                25,
              )}`}
            >
              {e.dayNumberText}
            </div>
          )}
        />
      </div>
    ));
  }, [e.dateProfile.currentRange, events, handleDateClick]);

  return <div className="calendarYear">{renderHtml}</div>;
};

export default CalendarsYear;
