import http from '@utils/http';

const basePath = '/api';

export const getMassageList = async (payload) => {
  const data = await http.get(`${basePath}/messages?${payload}`);

  return data.data.data;
};

export const createMassage = async (payload) => {
  const data = await http.post(`${basePath}/messages`, payload);

  return data.data.data;
};

export const updateMassage = async (payload) => {
  const data = await http.put(`${basePath}/messages/${payload.id}`, payload);

  return data.data.data;
};

export const deleteMassage = async (id) => {
  const data = await http.delete(`${basePath}/messages/${id}`);

  return data.data.data;
};
