import i18n from '@i18n';
import { ProjectAddHttp } from '@services/http';
import { LocalStorage } from '@services/storage';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal, ModalType, showModal } from '@store/Modal';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import {
  createClientFailure,
  createClientSuccess,
  createEmployeeFailure,
  createEmployeeSuccess,
  createEquipmentFailure,
  createEquipmentRequest,
  createEquipmentSuccess,
  createManagerFailure,
  createManagerSuccess,
  createProjectFailure,
  createProjectFinishFailure,
  createProjectFinishSuccess,
  createProjectSuccess,
  createResponsibleFailure,
  createResponsibleSuccess,
  createStaffFailure,
  createStaffSuccess,
  createTransportFailure,
  createTransportSuccess,
  getEquipmentEventFailure,
  getEquipmentEventSuccess,
  getLegacyEventFailure,
  getLegacyEventSuccess,
  getListBookingFailure,
  getListBookingSuccess,
  getListEquipmentSelectFailure,
  getListEquipmentSelectSuccess,
  getOfficeEventFailure,
  getOfficeEventSuccess,
  getOrganizersEventFailure,
  getOrganizersEventSuccess,
  getTransportListFailure,
  getTransportListSuccess,
  getUserEventFailure,
  getUserEventSuccess,
  getUserListFailure,
  getUserListSuccess,
  getWarehouseEventFailure,
  getWarehouseEventSuccess,
  isLoadedEquipmentsEvent,
  projectDeleteFailure,
  projectDeleteSuccess,
  projectValidationFailure,
  projectValidationSuccess,
  setStep,
} from './ProjectAdd.action';
import {
  CREATE_CLIENT_REQUEST,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_MANAGER_REQUEST,
  CREATE_PROJECT_FINISH_REQUEST,
  CREATE_PROJECT_REQUEST,
  CREATE_RESPONSIBLE_REQUEST,
  CREATE_STAFF_REQUEST,
  CREATE_TRANSPORT_REQUEST,
  GET_EQUIPMENT_EVENT_REQUEST,
  GET_LEGACY_EVENT_REQUEST,
  GET_LIST_BOOKING_REQUEST,
  GET_LIST_EQUIPMENT_SELECT_REQUEST,
  GET_OFFICE_EVENT_REQUEST,
  GET_ORGANIZERS_EVENT_REQUEST,
  GET_TRANSPORT_LIST_REQUEST,
  GET_USER_EVENT_REQUEST,
  GET_USER_LIST_REQUEST,
  GET_WAREHOUSE_EVENT_REQUEST,
  PROJECT_DELETE_REQUEST,
  PROJECT_VALIDATION_REQUEST,
} from './ProjectAdd.constant';

function* workerGetOrganizerEvent() {
  try {
    const data = yield call(ProjectAddHttp.getOrganizerEvent);

    yield put(getOrganizersEventSuccess(data));
  } catch (error) {
    yield put(getOrganizersEventFailure());
    yield put(alertError());
  }
}
function* watchGetOrganizerEvent() {
  yield takeLatest(GET_ORGANIZERS_EVENT_REQUEST, workerGetOrganizerEvent);
}

function* workerGetUserEvent() {
  try {
    const data = yield call(ProjectAddHttp.getUserEvent);

    yield put(getUserEventSuccess(data));
  } catch (error) {
    yield put(getUserEventFailure());
    yield put(alertError());
  }
}
function* watchGetUserEvent() {
  yield takeLatest(GET_USER_EVENT_REQUEST, workerGetUserEvent);
}

function* workerCreateClient({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createClient, payload);

    yield put(createClientSuccess(data));
    yield put(hideModal());
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_CUSTOMER'),
      }),
    );
  } catch (error) {
    yield put(createClientFailure());
    yield put(alertError(error));
  }
}
function* watchCreateClient() {
  yield takeLatest(CREATE_CLIENT_REQUEST, workerCreateClient);
}

function* workerCreateProject({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createProject, payload);

    yield put(createProjectSuccess(data));

    yield put(setStep(1));

    LocalStorage.set('project_step', 1);

    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_PROJECT'),
      }),
    );
  } catch (error) {
    yield put(createProjectFailure());
    yield put(alertError());
  }
}
function* watchCreateProject() {
  yield takeLatest(CREATE_PROJECT_REQUEST, workerCreateProject);
}

function* workerCreateEmployee({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createEmployee, payload);

    yield put(createEmployeeSuccess(data));
    yield put(hideModal());
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_EMPLOYEE'),
      }),
    );
  } catch (error) {
    yield put(createEmployeeFailure());
    yield put(alertError());
  }
}
function* watchCreateEmployee() {
  yield takeLatest(CREATE_EMPLOYEE_REQUEST, workerCreateEmployee);
}

function* workerGetOfficeEvent() {
  try {
    const data = yield call(ProjectAddHttp.getOfficeEvent);

    yield put(getOfficeEventSuccess(data));
  } catch (error) {
    yield put(getOfficeEventFailure());
    yield put(alertError());
  }
}
function* watchGetOfficeEvent() {
  yield takeLatest(GET_OFFICE_EVENT_REQUEST, workerGetOfficeEvent);
}

function* workerGetLegacyEvent() {
  try {
    const data = yield call(ProjectAddHttp.getLegacyEvent);

    yield put(getLegacyEventSuccess(data));
  } catch (error) {
    yield put(getLegacyEventFailure());
    yield put(alertError());
  }
}
function* watchGetLegacyEvent() {
  yield takeLatest(GET_LEGACY_EVENT_REQUEST, workerGetLegacyEvent);
}

function* workerGetTransportList() {
  try {
    const data = yield call(ProjectAddHttp.getTransport);

    yield put(getTransportListSuccess(data));
  } catch (error) {
    yield put(getTransportListFailure());
    yield put(alertError());
  }
}
function* watchGetTransportList() {
  yield takeLatest(GET_TRANSPORT_LIST_REQUEST, workerGetTransportList);
}

function* workerGetUserList() {
  try {
    const data = yield call(ProjectAddHttp.getUsers);

    yield put(getUserListSuccess(data));
  } catch (error) {
    yield put(getUserListFailure());
    yield put(alertError());
  }
}
function* watchGetUserList() {
  yield takeLatest(GET_USER_LIST_REQUEST, workerGetUserList);
}

function* workerCreateResponsible({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createResponsible, payload);

    yield put(createResponsibleSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_USER') }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(createResponsibleFailure());
    yield put(alertError());
  }
}
function* watchCreateResponsible() {
  yield takeLatest(CREATE_RESPONSIBLE_REQUEST, workerCreateResponsible);
}

function* workerCreateTransport({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createTransport, payload);

    yield put(createTransportSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_TRANSPORT'),
      }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(createTransportFailure());
    yield put(alertError(error));
  }
}
function* watchCreateTransport() {
  yield takeLatest(CREATE_TRANSPORT_REQUEST, workerCreateTransport);
}

function* workerCreateStaff({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createStaff, payload);

    yield put(createStaffSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_STAFF'),
      }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(createStaffFailure());
    yield put(alertError(error));
  }
}
function* watchCreateStaff() {
  yield takeLatest(CREATE_STAFF_REQUEST, workerCreateStaff);
}

function* workerCreateManager({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createManager, payload);

    yield put(createManagerSuccess(data));
    yield put(setStep(2));

    LocalStorage.set('project_step', 2);

    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_LINKED_MANAGER'),
      }),
    );
  } catch (error) {
    yield put(createManagerFailure());
    yield put(alertError());
  }
}
function* watchCreateManager() {
  yield takeLatest(CREATE_MANAGER_REQUEST, workerCreateManager);
}

function* workerGetEquipmentEvent() {
  try {
    const data = yield call(ProjectAddHttp.getEquipmentEvent);

    yield put(getEquipmentEventSuccess(data));
  } catch (error) {
    yield put(getEquipmentEventFailure());
    yield put(alertError());
  }
}
function* watchGetEquipmentEvent() {
  yield takeLatest(GET_EQUIPMENT_EVENT_REQUEST, workerGetEquipmentEvent);
}

function* workerGetWarehouseEvent() {
  try {
    const data = yield call(ProjectAddHttp.getWarehouseEvent);

    yield put(getWarehouseEventSuccess(data));
  } catch (error) {
    yield put(getWarehouseEventFailure());
    yield put(alertError());
  }
}
function* watchGetWarehouseEvent() {
  yield takeLatest(GET_WAREHOUSE_EVENT_REQUEST, workerGetWarehouseEvent);
}

function* workerGetListBookingEvent({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.getListBooking, payload);

    yield put(getListBookingSuccess(data));
  } catch (error) {
    yield put(getListBookingFailure());
    yield put(alertError());
  }
}
function* watchGetListBookingEvent() {
  yield takeLatest(GET_LIST_BOOKING_REQUEST, workerGetListBookingEvent);
}

function* workerGetListEquipmentSelect({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.getListEquipmentSelect, payload);

    yield put(
      getListEquipmentSelectSuccess(
        data.filter((item) => +item.group[0].count),
      ),
    );
  } catch (error) {
    yield put(getListEquipmentSelectFailure());
    yield put(alertError());
  }
}
function* watchGetListEquipmentSelect() {
  yield takeLatest(
    GET_LIST_EQUIPMENT_SELECT_REQUEST,
    workerGetListEquipmentSelect,
  );
}

function* workerCreateEquipmen({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createEquipment, payload.request);

    yield put(createEquipmentSuccess(data));
    yield put(isLoadedEquipmentsEvent(true));

    if (payload.red) {
      yield put(setStep(3));
      LocalStorage.set('project_step', 3);

      yield put(
        alertSuccess({
          message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_HARDWARE'),
        }),
      );
    }
  } catch (error) {
    yield put(createEquipmentFailure());
    yield put(alertError());
  }
}
function* watchCreateEquipmen() {
  yield takeLatest(CREATE_EQUIPMENT_REQUEST, workerCreateEquipmen);
}

function* workerCreateProjectFinish({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createProjectFinish, payload);

    yield put(createProjectFinishSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_PROJECT'),
      }),
    );
    // yield window.location.replace(`${LINK_PROJECTS}/${data.id}`);
  } catch (error) {
    yield put(createProjectFinishFailure());
    yield put(alertError());
  }
}
function* watchCreateProjectFinish() {
  yield takeLatest(CREATE_PROJECT_FINISH_REQUEST, workerCreateProjectFinish);
}

function* workerProjectDelete({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.projectDelete, payload);

    yield put(projectDeleteSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_DELETE_PROJECT'),
      }),
    );
  } catch (error) {
    yield put(projectDeleteFailure());
    yield put(alertError());
  }
}
function* watchProjectDelete() {
  yield takeLatest(PROJECT_DELETE_REQUEST, workerProjectDelete);
}

function* workerProjectValidation({ payload }) {
  try {
    const data = yield call(
      ProjectAddHttp.projectValidation,
      payload.validation,
    );

    yield put(isLoadedEquipmentsEvent(false));

    if (+data.length) {
      yield put(
        showModal({
          modalType: ModalType.INTERSECTIONS,
          modalProps: {
            data,
          },
        }),
      );
    } else {
      yield put(createEquipmentRequest(payload));
    }

    yield put(projectValidationSuccess(data));
  } catch (error) {
    yield put(projectValidationFailure());
  }
}
function* watchProjectValidation() {
  yield takeLatest(PROJECT_VALIDATION_REQUEST, workerProjectValidation);
}

export const projectAddWatchers = [
  fork(watchProjectDelete),
  fork(watchGetOrganizerEvent),
  fork(watchGetUserEvent),
  fork(watchCreateClient),
  fork(watchCreateProject),
  fork(watchCreateEmployee),
  fork(watchGetOfficeEvent),
  fork(watchGetLegacyEvent),
  fork(watchGetTransportList),
  fork(watchGetUserList),
  fork(watchCreateResponsible),
  fork(watchCreateTransport),
  fork(watchCreateStaff),
  fork(watchCreateManager),
  fork(watchGetEquipmentEvent),
  fork(watchGetEquipmentEvent),
  fork(watchGetWarehouseEvent),
  fork(watchGetListBookingEvent),
  fork(watchGetListEquipmentSelect),
  fork(watchCreateEquipmen),
  fork(watchCreateProjectFinish),
  fork(watchProjectValidation),
];
