import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    name: yup.string().trim().required(i18n.t('VALIDATION.REQUIRED')),
    category: yup.object().nullable().required(i18n.t('VALIDATION.REQUIRED')),
    port: yup.object().nullable().required(i18n.t('VALIDATION.REQUIRED')),
    quantity: yup
      .string()
      .required(i18n.t('VALIDATION.REQUIRED'))
      .matches(/^[0-9]+$/),
    price: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    length: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
  });
