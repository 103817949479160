import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ProjectAddEquipmentTime = ({ className, endDate, startDate }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('addEvent-table__info', { className })}>
      <div className="h6">
        <span className="fw600">{t('COMMON.BEGINNING')}</span>
        <span className="h6 color_grey"> {startDate} </span>
      </div>

      <div className="h6">
        <span className="h6 fw600">{t('COMMON.ENDING')}</span>
        <span className="h6 color_grey"> {endDate}</span>
      </div>
    </div>
  );
};

export default ProjectAddEquipmentTime;
