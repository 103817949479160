import { Fragment, useCallback, useEffect } from 'react';

import { useResponsive } from '@hooks';
import { useAppDispatch } from '@store/';
import { ModalType, showModal } from '@store/Modal';
import { UICalendar } from '@ui';
import {
  CALENDAR_TYPE_DAY,
  CALENDAR_TYPE_WEEK,
  FORMAT_DAY_WITH_MONTH_ALT,
  FORMAT_WEEK,
  FORMAT_WEEK_WITH_DAY_WITH_MONTH,
  FORMAT_WEEK_WITH_DAY_WITH_MONTH_MOB,
  UNIX_DAY,
  UNIX_WEEK,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import cn from 'classnames';

import CalendarsCommonList from './List';
import parserData from './parserData';

const CalendarsCommon = ({
  e,
  type,
  className,
  handleDateClick,
  eventsInfo,
  setRange,
  range,
}) => {
  const daysToCalendar = type === CALENDAR_TYPE_WEEK ? 7 : 1;
  const data = parserData(e, type, daysToCalendar);
  const { isDesktopXL } = useResponsive();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === CALENDAR_TYPE_WEEK && !range && e.dateProfile?.activeRange) {
      setRange({
        endDate: new Date(e.dateProfile?.activeRange.end.valueOf() - UNIX_DAY),
        key: 'selection',
        startDate: e.dateProfile?.activeRange.start,
      });
    }
  }, [e.dateProfile?.activeRange, range, setRange, type]);

  const WeekHeader = ({ index, start, daysToCalendar }) => {
    const dateWeek = [];

    for (let i = 0; i < daysToCalendar; i++) {
      dateWeek.push(
        DateHelper.toFormat(
          start.valueOf() + UNIX_DAY * i + UNIX_WEEK * index,
          isDesktopXL
            ? FORMAT_WEEK_WITH_DAY_WITH_MONTH
            : FORMAT_WEEK_WITH_DAY_WITH_MONTH_MOB,
        ),
      );
    }

    return dateWeek.map((item, index) => (
      <td className="h5 fw600 color_blue" key={index}>
        {item}
      </td>
    ));
  };

  const BuildRow = useCallback(
    ({ events, daysToCalendar }) => {
      const usedEvents = [...events];
      let eventsToRow = 1;
      const weekArr = Array.from(Array(daysToCalendar + 1).keys());

      weekArr.shift();

      const searchEvents = (events, start) => {
        let foundItem = null;

        if (!events) return null;

        events.forEach((item, index) => {
          if (item.start === start && !foundItem) {
            foundItem = { index, item };
          }
        });

        return foundItem;
      };

      return (
        <>
          <tr className="calendar-event__row">
            {weekArr.map((item, index) => {
              const event = searchEvents(usedEvents, item);

              if (event && eventsToRow === item) {
                eventsToRow += event.item.duration;
                usedEvents.splice(event.index, 1);

                return (
                  <td
                    className="calendar-event__wrap"
                    key={index}
                    colSpan={
                      event.item.duration > daysToCalendar - 1
                        ? daysToCalendar
                        : event.item.duration
                    }
                  >
                    <div
                      onClick={(e) =>
                        dispatch(
                          showModal({
                            modalType: ModalType.CALENDAR_EVENT,
                            modalProps: {
                              event: {
                                id: event.item.id,
                                title: event.item.title,
                              },
                              pageX: e.pageX,
                              clientY: e.clientY,
                              pageY: e.pageY,
                            },
                          }),
                        )
                      }
                      className="h6"
                      style={event.item.style}
                    >
                      {event.item.title}
                    </div>
                  </td>
                );
              }

              if (!event && eventsToRow === item) {
                eventsToRow += 1;

                return <td key={index} />;
              }

              return null;
            })}
          </tr>
          {usedEvents.length ? (
            <BuildRow daysToCalendar={daysToCalendar} events={usedEvents} />
          ) : null}
        </>
      );
    },
    [dispatch],
  );

  return (
    <div className={cn(className, 'component__calendar')}>
      <table
        className={cn({
          two__column: isDesktopXL && type === CALENDAR_TYPE_DAY,
        })}
      >
        <tbody>
          {data.map((item, index) => (
            <Fragment key={index}>
              <tr className="calendar-event__header">
                {type === CALENDAR_TYPE_WEEK ? (
                  <WeekHeader
                    daysToCalendar={daysToCalendar}
                    index={index}
                    start={e.dateProfile?.activeRange.start}
                  />
                ) : (
                  <td className="h3 fw600">
                    {DateHelper.toFormat(
                      e.dateProfile?.activeRange.start,
                      FORMAT_DAY_WITH_MONTH_ALT,
                    )}
                    <span className="h4 fw400 color_blue">
                      {DateHelper.toFormat(
                        e.dateProfile?.activeRange.start,
                        FORMAT_WEEK,
                      )}
                    </span>
                  </td>
                )}
              </tr>
              {Object.keys(item).map((item2, index) => (
                <Fragment key={index}>
                  <tr className="component__calendar__time">
                    <td colSpan={daysToCalendar}>
                      <div className="color_blue">{item2}</div>
                    </td>
                  </tr>
                  <BuildRow
                    daysToCalendar={daysToCalendar}
                    events={item[item2]}
                  />
                </Fragment>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
      {type === CALENDAR_TYPE_DAY && isDesktopXL ? (
        <UICalendar
          initialDate={e.dateProfile.activeRange.start}
          handleDateClick={handleDateClick}
          selectedDate={e.dateProfile?.activeRange.start}
        >
          <CalendarsCommonList eventsInfo={eventsInfo} />
        </UICalendar>
      ) : null}
    </div>
  );
};

export default CalendarsCommon;
