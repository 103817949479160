/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { AddCircleSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';

import { ModalType, showModal } from '@store/Modal';
import { UIButton } from '@ui';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';

import { LocalStorage } from '@services/storage';
import EquipmentsAddEquipments from './AddEquipments';
import EquipmentsFormFilter from './FormFilter';
import EquipmentCards from './Cards';

import './styles.scss';

const Equipments = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [edit, setEdit] = useState(null);
  const [mockData, setMockData] = useState([]);

  const categoriesArr = [{ id: 1, name: 'Sailboat' }];

  const portArr = [
    { id: 1, name: 'Saint Petersburg' },
    { id: 2, name: 'Crimea' },
    { id: 3, name: 'London' },
    { id: 4, name: 'Guangzhou' },
    { id: 5, name: 'Qingdao' },
    { id: 6, name: 'Saint Petersburg' },
  ];

  const handlerOpenEquipment = () => {
    dispatch(
      showModal({
        modalType: ModalType.ADD_EQUIPMENT,
        modalProps: {
          edit,
          resetEdit: () => setEdit(null),
        },
      }),
    );
  };

  useEffect(() => {
    if (!mockData.length && JSON.parse(!LocalStorage.get('equipments'))) {
      LocalStorage.set(
        'equipments',
        JSON.stringify([
          {
            id: 1,
            name: 'Titania',
            category: { id: 1, name: 'Sailboat' },
            port: { id: 3, name: 'Saint Petersburg' },
            quantity: 10,
            length: 180.65,
            price: 140000,
            fileList: [
              {
                id: '1',
                name: 'image.jpeg',
                url: 'https://drikus.club/uploads/posts/2021-12/1640670850_1-drikus-club-p-parusnie-yakhti-tekhnika-krasivo-foto-1.jpg',
              },
            ],
          },
          {
            id: 2,
            name: 'Thomas Edison',
            category: { id: 1, name: 'Sailboat' },
            port: { id: 2, name: 'Crimea' },
            quantity: 3,
            length: 25,
            price: 90000,
            fileList: [
              {
                id: '2',
                name: 'image.jpeg',
                url: 'https://sportishka.com/uploads/posts/2021-12/1639127613_30-sportishka-com-p-parusnaya-lodka-sport-krasivo-foto-37.jpg',
              },
            ],
          },
          {
            id: 3,
            name: 'Avrora',
            category: { id: 1, name: 'Sailboat' },
            port: { id: 3, name: 'London' },
            quantity: 4,
            length: 90,
            price: 1000,
            fileList: [
              {
                id: '3',
                name: 'image.jpg',
                url: 'https://i.artfile.ru/1920x1200_1198700_[www.ArtFile.ru].jpg',
              },
            ],
          },
          {
            id: 4,
            name: 'Bufalo',
            category: { id: 1, name: 'Sailboat' },
            port: { id: 4, name: 'Guangzhou' },
            quantity: 4,
            length: 89,
            price: 10000,
            fileList: [
              {
                id: '4',
                name: 'image.jpg',
                url: 'https://hdpic.club/uploads/posts/2022-08/1660568818_29-hdpic-club-p-parusnaya-yakhta-34.jpg',
              },
            ],
          },
          {
            id: 5,
            name: 'Steevin',
            category: { id: 1, name: 'Sailboat' },
            port: { id: 4, name: 'Busan' },
            quantity: 4,
            length: 150.55,
            price: 8000,
            fileList: [
              {
                id: '5',
                name: 'image.jpg',
                url: 'https://i.pinimg.com/originals/a9/ea/be/a9eabebf1f093a919330ca2b0b42b184.jpg',
              },
            ],
          },
          {
            id: 6,
            name: 'Adinburg',
            category: { id: 1, name: 'Sailboat' },
            port: { id: 4, name: 'Saint Petersburg' },
            quantity: 4,
            length: 93.55,
            price: 32000,
            fileList: [
              {
                id: '5',
                name: 'image.jpg',
                url: 'https://premiumyachts.ru/06-mahina-photo-credit-tor-johnson.jpg',
              },
            ],
          },
        ]),
      );
    }
  }, [mockData]);

  useEffect(() => {
    if (mockData.length) {
      LocalStorage.set('equipments', JSON.stringify(mockData));
    }
  }, [mockData]);

  useEffect(() => {
    if (LocalStorage.get('equipments')) {
      setMockData(JSON.parse(LocalStorage.get('equipments')));
    }
  }, []);

  return (
    <>
      <div className="equipment-left">
        <div className="content__title">{t('COMMON.PRODUCTS')}</div>

        <UIButton
          type={Button.BUTTON}
          handler={handlerOpenEquipment}
          className="addElement equipment-add hide-desktop show-mobile"
        >
          <AddCircleSVG />
          <span className="h6 color_darkBlue">
            {edit ? t('COMMON.EDIT') : t('COMMON.ADD')}
          </span>
        </UIButton>

        <EquipmentCards setEdit={setEdit} data={mockData} />
      </div>

      <div className="equipment-new">
        <div className="equipment-new__title h3 fw600">
          {edit ? t('COMMON.EDIT') : t('COMMON.ADD')}
        </div>

        <EquipmentsAddEquipments
          categories={categoriesArr}
          ports={portArr}
          edit={edit}
          setEdit={setEdit}
          setMockData={setMockData}
          mockData={mockData}
          resetEdit={() => setEdit(null)}
        />
      </div>
    </>
  );
};

export default Equipments;
