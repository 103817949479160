import { useTranslation } from 'react-i18next';

const EquipmentCards = ({ setEdit, data }) => {
  const { t } = useTranslation();

  return (
    <div className="boat-list-wrapp">
      {data?.map((item, index) => (
        <div
          className="boat-item"
          key={index}
          onClick={() => setEdit(data[index])}
        >
          <img
            src={item?.fileList?.[0]?.url || item?.fileList?.[0]?.thumbUrl}
            alt="img"
          />
          <div className="boat-item-info">
            <div>
              <p>{item.name}</p>
              <span>{item.category.name}</span>
            </div>
            <div>
              <p>{item.quantity}</p>
              <span>{t('COMMON.QUANTITY')}</span>
            </div>
            <div>
              <p>{item.length}m</p>
              <span>{t('COMMON.LENGTH')}</span>
            </div>
            <div>
              <p>{item.port.name}</p>
              <span>{t('COMMON.PORT')}</span>
            </div>
            <div>
              <p>{item.price}$</p>
              <span>{t('COMMON.PRICE')}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EquipmentCards;
