/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-template */
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { Modal, Upload } from 'antd';
import { PlusSVG } from '@assets/img/icons';

import { UIButton, UIInput, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import WidgetsModalImageCrop from '../../../widgets/Modal/ImageCrop';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EquipmentsAddEquipments = ({
  edit,
  resetEdit,
  categories,
  ports,
  setMockData,
  mockData,
}) => {
  const { t, i18n } = useTranslation();

  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlerSubmit = ({ ...val }, { resetForm }) => {
    if (edit) {
      const newState = mockData.map((obj) => {
        if (obj.id === val.id) {
          return { ...obj, ...val };
        }

        return obj;
      });

      setMockData(newState);
    } else {
      setMockData((prevValue) => [
        ...prevValue,
        { id: prevValue.length + 1, ...val },
      ]);
    }

    resetForm();
    setInitialValues(INITIAL_VALUES);
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (edit) {
      setInitialValues({
        ...INITIAL_VALUES,
        ...edit,
      });
    }
  }, [edit]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="upload-btn">{t('COMMON.UPLOAD')}</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && !file.thumbUrl) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.thumbUrl) {
      setPreviewImage(file.thumbUrl);
    } else {
      setPreviewImage(file.url || file.preview);
    }

    setPreviewOpen(true);
  };

  return (
    <form onSubmit={form.handleSubmit} className="equipment-new__items">
      <UIButton
        defaultClassName="equipment__clear h6 fw600"
        type={Button.BUTTON}
        handler={() => {
          setInitialValues(INITIAL_VALUES);
          form.resetForm();
          resetEdit();
        }}
      >
        {t('COMMON.CLEAR')}
      </UIButton>
      <UIInput
        name="name"
        islabelRow
        id="name"
        label={t('COMMON.TITLE')}
        classLabel="fw600 icon_necessarily"
        className="input input__row"
        placeholder={t('COMMON.TITLE')}
        value={form.values?.name}
        onChange={form.handleChange}
        isValid={!(form.errors.name && form.touched.name)}
        validateText={form.errors.name}
      />
      <UISelect
        name="category"
        className="select-wrap custom-select-row"
        label={t('COMMON.CATEGORY')}
        classLabel="h6 icon_necessarily fw600"
        options={categories}
        placeholder={t('COMMON.CATEGORY')}
        value={form.values?.category}
        onChange={(value) => form.setFieldValue('category', value)}
        isValid={!(form.errors.category && form.touched.category)}
        validateText={form.errors.category}
      />
      <UISelect
        name="port"
        className="select-wrap custom-select-row"
        label={t('COMMON.PORT')}
        classLabel="h6 icon_necessarily fw600"
        options={ports}
        placeholder={t('COMMON.PORT')}
        value={form.values?.port}
        onChange={(value) => form.setFieldValue('port', value)}
        isValid={!(form.errors.port && form.touched.port)}
        validateText={form.errors.port}
      />
      <UIInput
        name="quantity"
        islabelRow
        label={t('COMMON.QUANTITY')}
        classLabel="fw600 icon_necessarily"
        className="input input__row"
        placeholder={t('COMMON.QUANTITY')}
        value={form.values?.quantity}
        onChange={form.handleChange}
        isValid={!(form.errors.quantity && form.touched.quantity)}
        validateText={form.errors.quantity}
      />
      <UIInput
        name="price"
        islabelRow
        label={t('COMMON.PRICE')}
        classLabel="fw600 icon_necessarily"
        className="input input__row"
        placeholder={t('COMMON.PRICE')}
        value={form.values?.price}
        onChange={form.handleChange}
        isValid={!(form.errors.price && form.touched.price)}
        validateText={form.errors.price}
      />
      <UIInput
        name="length"
        islabelRow
        label={t('COMMON.LENGTH')}
        classLabel="fw600 icon_necessarily"
        className="input input__row"
        placeholder={t('COMMON.LENGTH')}
        value={form.values?.length}
        onChange={form.handleChange}
        isValid={!(form.errors.length && form.touched.length)}
        validateText={form.errors.length}
      />
      <WidgetsModalImageCrop isCircle>
        <Upload
          listType="picture-card"
          fileList={form.values.fileList}
          onPreview={handlePreview}
          onChange={(file) => {
            form.setFieldValue('fileList', file.fileList);
          }}
          onRemove={(file) => {
            form.setFieldValue(
              'fileList',
              form.values.fileList.filter((i) => i.uid !== file.uid),
            );
          }}
        >
          {form.values.fileList.length >= 8 ? null : uploadButton}
        </Upload>
      </WidgetsModalImageCrop>

      <Modal
        open={previewOpen}
        wrapClassName="upload-preview-modal"
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <div className="equipment-new__btn mt-30">
        <UIButton className="btn outline__blue" type={Button.SUBMIT}>
          {edit ? (
            <>{t('COMMON.UPDATE')}</>
          ) : (
            <>
              <PlusSVG />
              <span>{t('COMMON.ADD')}</span>
            </>
          )}
        </UIButton>
      </div>
    </form>
  );
};

export default EquipmentsAddEquipments;
