import { useEffect, useState } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import { createEmployeeRequest, resetAction } from '@store/ProjectAdd';
import { UIButton, UIColor, UIError, UIInput, UIRadio, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const ProjectAddMainEmployeeForm = ({ handler }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { loading, isProjectAction } = useSelector((state) => state.projectAdd);

  const [color, setColor] = useState();
  const [isOpenColor, setIsOpenColor] = useState(false);

  const handlerSubmit = (values) => {
    dispatch(createEmployeeRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (color && form.values.color !== color) {
      form.setFieldValue('color', color);
    }
  }, [color, form]);

  useEffect(() => {
    if (isProjectAction) {
      form.resetForm();
      dispatch(resetAction());
    }
  }, [dispatch, form, isProjectAction]);

  const radioInput = [
    { id: 'inactive', value: '1', label: i18n.t('COMMON.ACTIVE') },
    { id: 'active', value: '0', label: i18n.t('COMMON.INACTIVE') },
  ];

  return (
    <form onSubmit={form.handleSubmit}>
      <div className="fw600 h3 addEvent-title mb-15">
        {t('PROJECT.ADD_EMPLOYEE')}

        <UIColor
          className="addEvent-title__color"
          setColor={setColor}
          color={color}
          setIsOpenColor={setIsOpenColor}
          isOpenColor={isOpenColor}
        />
      </div>

      <div className="addEvent-add__items">
        <UIButton
          defaultClassName="ml-auto d-flex color_grey h6 fw600"
          type={Button.BUTTON}
          handler={() => form.resetForm()}
        >
          {t('COMMON.CLEAR')}
        </UIButton>

        <UIInput
          label={t('COMMON.EMAIL')}
          name="email"
          placeholder={t('COMMON.EMAIL')}
          classLabel="fw600"
          value={form.values.email}
          onChange={form.handleChange}
          isValid={!(form.errors.email && form.touched.email)}
          validateText={form.errors.email}
        />

        <UIInput
          label={t('COMMON.NAME')}
          name="first_name"
          placeholder={t('COMMON.NAME')}
          classLabel="fw600"
          value={form.values.first_name.trimStart()}
          onChange={form.handleChange}
          isValid={!(form.errors.first_name && form.touched.first_name)}
          validateText={form.errors.first_name}
        />

        <UIInput
          label={t('COMMON.SURNAME')}
          name="last_name"
          placeholder={t('COMMON.SURNAME')}
          classLabel="fw600"
          value={form.values.last_name.trimStart()}
          onChange={form.handleChange}
          isValid={!(form.errors.last_name && form.touched.last_name)}
          validateText={form.errors.last_name}
        />

        <UIInput
          label={t('COMMON.PHONE')}
          name="phone"
          placeholder={t('COMMON.PHONE')}
          classLabel="fw600"
          value={form.values.phone.trimStart()}
          onChange={form.handleChange}
          isValid={!(form.errors.phone && form.touched.phone)}
          validateText={form.errors.phone}
        />

        <div className="select-column">
          <UISelect
            id="role"
            label={t('COMMON.ROLE')}
            classLabel="h6 fw600"
            options={[{ id: 'admin', name: t('COMMON.ADMINISTRATOR') }]}
            name="role"
            onChange={(value) => {
              form.setFieldValue('role', value?.id || '');
            }}
            placeholder={t('COMMON.ROLE')}
            isValid={!(form.errors.role && form.touched.role)}
            validateText={form.errors.role}
          />
        </div>

        <UIInput
          label={t('COMMON.PASSWORD')}
          name="password"
          type="password"
          className="input input__icon"
          placeholder={t('COMMON.ENTER_PASSWORD')}
          classLabel="fw600"
          value={form.values.password}
          onChange={form.handleChange}
          isValid={!(form.errors.password && form.touched.password)}
          validateText={form.errors.password}
          isShowPassword
        />

        <div className="addEvent-radio radio-wrap">
          <span className="h6 fw600">{t('COMMON.STATUS')}</span>
          <div>
            {radioInput?.map((item, index) => (
              <UIRadio
                key={index}
                id={item.id}
                type="radio"
                name="active"
                className="custom-radio"
                value={item.value}
                onChange={form.handleChange}
              >
                {item.label}
              </UIRadio>
            ))}
          </div>
          <UIError
            isValid={!(form.errors.active && form.touched.active)}
            text={form.errors.active}
          />
        </div>
      </div>

      <div className="addEvent-add__btn">
        <UIButton
          loading={loading}
          className="outline__blue"
          type={Button.SUBMIT}
          handler={() => form.resetForm()}
        >
          <PlusSVG />
          <span>{t('COMMON.ADD')}</span>
        </UIButton>

        <UIButton
          className="btn__gray addEvent-remove__employee"
          handler={handler}
        >
          {t('COMMON.CANCEL')}
        </UIButton>
      </div>
    </form>
  );
};

export default ProjectAddMainEmployeeForm;
