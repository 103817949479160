import i18n from '@i18n';
import { TransferHttp } from '@services/http';
import { alertError, alertErrorWrap, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  changeTransferFailure,
  changeTransferRequest,
  changeTransferSuccess,
  deleteTransferFailure,
  deleteTransferSuccess,
  newTransferFailure,
  newTransferRequest,
  newTransferSuccess,
  searchTransferFailure,
  searchTransferSuccess,
  transferAllFailure,
  transferAllSuccess,
  validationTransferFailure,
  validationTransferSuccess,
} from './Transfer.action';
import {
  CHANGE_TRANSFER_REQUEST,
  DELETE_TRANSFER_REQUEST,
  NEW_TRANSFER_REQUEST,
  SEARCH_TRANSFER_REQUEST,
  TRANSFER_ALL_REQUEST,
  VALIDATION_TRANSFER_REQUEST,
} from './Transfer.constant';

function* workerSearchTransfer({ payload }) {
  try {
    const data = yield call(TransferHttp.getTransfer, payload);

    yield put(searchTransferSuccess(data));
    yield !data.data.length &&
      put(alertErrorWrap(i18n.t('ALERT.NO_TRANSFERS_FOUND')));
  } catch (error) {
    yield put(searchTransferFailure());
    yield put(alertError());
  }
}
function* watchSearchTransfer() {
  yield takeLatest(SEARCH_TRANSFER_REQUEST, workerSearchTransfer);
}

function* workerTransferAll({ payload }) {
  try {
    const data = yield call(TransferHttp.getTransferAll, payload);
    const dataFilter = data.data.filter(
      (item) =>
        +payload.filter?.group_at &&
        +item.group?.find((i) => i.id === payload.filter?.group_at).count !== 0,
    );

    yield put(
      transferAllSuccess({
        meta: data.meta,
        data: dataFilter,
      }),
    );
    yield !dataFilter.length &&
      put(alertErrorWrap(i18n.t('ALERT.EQUIPMENT_NOT_FOUND')));
  } catch (error) {
    yield put(transferAllFailure());
    yield put(alertError());
  }
}
function* watchTransferAll() {
  yield takeLatest(TRANSFER_ALL_REQUEST, workerTransferAll);
}

function* workerNewTransfer({ payload }) {
  try {
    const data = yield call(TransferHttp.newTransfer, payload);

    yield put(newTransferSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.TRANSFER_CREATED') }));
    yield put(hideModal());
  } catch (error) {
    yield put(newTransferFailure());
    yield put(alertError());
  }
}
function* watchNewTransfer() {
  yield takeLatest(NEW_TRANSFER_REQUEST, workerNewTransfer);
}

function* workerChangeTransfer({ payload }) {
  try {
    const data = yield call(TransferHttp.putTransfer, payload);

    yield put(changeTransferSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.TRANSFER_CHANGED') }));
  } catch (error) {
    yield put(changeTransferFailure());
    yield put(alertError());
  }
}
function* watchChangeTransfer() {
  yield takeLatest(CHANGE_TRANSFER_REQUEST, workerChangeTransfer);
}

function* workerDeleteTransfer({ payload }) {
  try {
    const data = yield call(TransferHttp.deleteTransfer, payload);

    yield put(deleteTransferSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.TRANSFER_DELETE') }));
    yield put(hideModal());
  } catch (error) {
    yield put(deleteTransferFailure());
    yield put(alertError(error));
    yield put(hideModal());
  }
}
function* watchDeleteTransfer() {
  yield takeLatest(DELETE_TRANSFER_REQUEST, workerDeleteTransfer);
}

function* workerValidationTransfer({ payload }) {
  try {
    const { type, ...val } = payload;
    const data = yield call(TransferHttp.validationTransfer, val);

    yield put(validationTransferSuccess(data));
    // if (+data.length) {
    //   yield put(
    //     showModal({
    //       modalType: ModalType.INTERSECTIONS,
    //       modalProps: {
    //         data,
    //       },
    //     }),
    //   );
    // } else {
    if (type === 'CREATE') {
      yield put(newTransferRequest(val));
    } else {
      yield put(changeTransferRequest(val));
    }
    // }
  } catch (error) {
    yield put(validationTransferFailure());
    yield put(alertError(error));
  }
}
function* watchValidationTransfer() {
  yield takeLatest(VALIDATION_TRANSFER_REQUEST, workerValidationTransfer);
}

export const transferWatchers = [
  fork(watchSearchTransfer),
  fork(watchNewTransfer),
  fork(watchChangeTransfer),
  fork(watchValidationTransfer),
  fork(watchDeleteTransfer),
  fork(watchTransferAll),
];
