/* eslint-disable indent */
import { useEffect, useRef, useState } from 'react';

import { CalendarSVG } from '@assets/img/icons';
import { useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import { searchEventsRequest } from '@store/Events';
import { UIButton, UIInput, UIRangeCalendar } from '@ui';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const EventsForm = ({ loading }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [isRangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const calendarRangeRef = useRef();
  const [range, setRange] = useState({
    endDate: new Date(),
    key: 'selection',
    startDate: new Date(),
  });

  useHidePopupOutside(calendarRangeRef, () => setRangeCalendarOpen(false));

  const handlerSubmit = (values, { setFieldValue }) => {
    setFieldValue('name', values.name.replace(/\s+/g, ' ').trim());
    dispatch(
      searchEventsRequest({
        name: values.name,
        date_start: DateHelper.toFormat(range.startDate, FORMAT_DATE_QUERY),
        date_finish: DateHelper.toFormat(range.endDate, FORMAT_DATE_QUERY),
      }),
    );
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handlerSelect = (date) => {
    setRange(date.selection);
    form.setFieldValue(
      'dateEvents',
      `${DateHelper.toFormat(
        date.selection.startDate,
        FORMAT_DATE_QUERY,
      )} - ${DateHelper.toFormat(date.selection.endDate, FORMAT_DATE_QUERY)}`,
    );
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <form onSubmit={form.handleSubmit} className="projectsAll-filter">
      <UIInput
        label={t('CALENDAR.REPORT_DATE')}
        placeholder={`${t('COMMON.START_DATE')} \u{2192} ${t(
          'COMMON.END_DATE',
        )}`}
        className="input input__row input__icon"
        classLabel="fw600 icon_necessarily"
        name="dateEvents"
        value={form.values.dateEvents}
        islabelRow
        isValid={!(form.errors.dateEvents && form.touched.dateEvents)}
        validateText={form.errors.dateEvents}
        onFocus={() => setRangeCalendarOpen(true)}
        wrapRef={calendarRangeRef}
        autoComplete="off"
      >
        <span
          className="input-icon__btn input-icon__calendar"
          onClick={() => setRangeCalendarOpen(true)}
        >
          <CalendarSVG />
          {isRangeCalendarOpen && (
            <div className="rangeCalendar">
              <UIRangeCalendar
                range={range}
                hidePopup={() => setRangeCalendarOpen(false)}
                handlerSelect={handlerSelect}
                showMonthAndYearPickers={false}
              />
            </div>
          )}
        </span>
      </UIInput>

      <UIInput
        name="name"
        islabelRow
        id="name"
        label={t('CALENDAR.EQUIPMENT_NAME')}
        classLabel="fw600 icon_necessarily"
        className="input input__row"
        placeholder={t('COMMON.EQUIPMENT')}
        value={form.values.name}
        onChange={form.handleChange}
        isValid={!(form.errors.name && form.touched.name)}
        validateText={form.errors.name}
      />

      <UIButton
        loading={loading}
        className="btn outline__blue"
        type={Button.SUBMIT}
      >
        {t('COMMON.FIND')}
      </UIButton>
    </form>
  );
};

export default EventsForm;
