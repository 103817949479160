import { useEffect, useState } from 'react';

import { useResponsive } from '@hooks';
import { useAppDispatch } from '@store/';
import {
  getOrganizersEventRequest,
  getUserEventRequest,
} from '@store/ProjectAdd/ProjectAdd.action';
import { useTranslation } from 'react-i18next';

import ProjectAddMainEmployee from './Employee';
import ProjectAddMainForm from './Form';
import ProjectAddMainClient from './Сlient';
import './style.scss';

const ProjectAddMain = () => {
  const [isShowClient, setShowClient] = useState(false);
  const [isShowEmployee, setShowEmployee] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isMobile, isDesktop, isIpad, isTablet } = useResponsive();

  useEffect(() => {
    dispatch(getOrganizersEventRequest());
    dispatch(getUserEventRequest());
  }, [dispatch]);

  return (
    <>
      <div className="addEvent-wrap addEvent-wrap__step1">
        <div className="addEvent-wrap__left">
          <div className="h3 fw600 mb-30">{t('COMMON.MAIN')}</div>

          <ProjectAddMainForm
            setShowClient={setShowClient}
            setShowEmployee={setShowEmployee}
          />
        </div>

        {isShowClient && !isDesktop && !isIpad && !isMobile && !isTablet && (
          <ProjectAddMainClient handler={() => setShowClient(false)} />
        )}
        {isShowEmployee && !isDesktop && !isIpad && !isMobile && !isTablet && (
          <ProjectAddMainEmployee handler={() => setShowEmployee(false)} />
        )}
      </div>
    </>
  );
};

export default ProjectAddMain;
