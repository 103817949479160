export const INITIAL_VALUES = {
  name: '',
  date_start_event: '',
  date_finish_event: '',
  edit: false,
  organizer_id: null,
  person_charge_id: null,
  dateEvent: null,
  step: 1,
};

export const checkDisableDate = (editData) => {
  if (editData && editData?.reservations.length) {
    const arr = editData?.reservations;

    const minDate = Math.min.apply(
      null,
      arr.map((i) => i.start),
    );

    const maxDate = Math.max.apply(
      null,
      arr.map((i) => i.finish),
    );

    return {
      min: new Date(minDate * 1000),
      max: new Date(maxDate * 1000),
    };
  }

  return null;
};
