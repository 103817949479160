import i18n from '@i18n';
import { FORMAT_WEEK_WITH_DAY_WITH_MONTH__ALT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';

const CalendarsCommonList = ({ eventsInfo }) => (
  <div className="calendar-day__infoWrap">
    {eventsInfo.map((item) => (
      <div key={item.id} className="calendar-day__info">
        <div className="h3 fw600">{item.name}</div>

        <div className="h5 fw600">
          {DateHelper.toFormat(
            item.date_start_event,
            FORMAT_WEEK_WITH_DAY_WITH_MONTH__ALT,
          )}
        </div>

        <div className="h5 fw600">{item.place}</div>

        {item.organizer ? (
          <div className="calendar-dayInfo__wrap">
            <div className="color_grey h6">{i18n.t('CALENDAR.ORGANIZER')}</div>
            <div className="h6">{item.organizer.name}</div>
            {item.organizer.phone ? (
              <div className="h6">{item.organizer.phone}</div>
            ) : null}
            {item.organizer.email ? (
              <div className="h6">{item.organizer.email}</div>
            ) : null}
          </div>
        ) : null}

        {item.user ? (
          <div className="calendar-dayInfo__wrap">
            <div className="color_grey h6">
              {i18n.t('CALENDAR.RESPONSIBLE_SIDE')}
            </div>
            {item.user.last_name || item.user.first_name ? (
              <div className="h6">{`${item.user.last_name} ${item.user.first_name}`}</div>
            ) : null}
            {item.user.phone ? (
              <div className="h6">{item.user.phone}</div>
            ) : null}
            {item.user.email ? (
              <div className="h6">{item.user.email}</div>
            ) : null}
          </div>
        ) : null}
      </div>
    ))}
  </div>
);

export default CalendarsCommonList;
