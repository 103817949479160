/* eslint-disable import/no-extraneous-dependencies */
import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ImgCrop from 'antd-img-crop';

import cn from 'classnames';

const WidgetsModalImageCrop = ({
  widthRatio = 250,
  heightRatio = 250,
  isCircle,
  children,
}) => {
  const { t } = useTranslation();

  const handleRequest = (file) => {
    console.log('file', file);
  };

  return (
    <ImgCrop
      onModalOk={handleRequest}
      aspect={widthRatio / heightRatio}
      modalClassName={cn('img-crop-modal', {
        wrappCircle: isCircle,
      })}
      modalTitle={t('COMMON.IMAGE_EDIT')}
      modalCancel={t('COMMON.CANCEL')}
      modalOk={t('COMMON.SAVE')}
      extraFooter={<div>sdassc</div>}
    >
      {children}
    </ImgCrop>
  );
};

export default memo(WidgetsModalImageCrop);
