import i18n from '@i18n';

import { UILoaderWrap } from '../Loader';
import UITableBody from './Body';
import UITableError from './Error';
import UITableRowHeader from './Header';

const UITable = ({
  className = 'custom-table big-table',
  classWrap,
  classTd,
  onClickRows,
  onClickCol,
  header,
  tdSpan,
  content,
  loading,
  textError = i18n.t('COMMON.NO_DATA_FOUND'),
  vertical = false,
}) => (
  <UILoaderWrap className={classWrap} loading={loading}>
    <table className={className}>
      <tbody>
        {!vertical ? <UITableRowHeader header={header} /> : null}
        {content?.length ? (
          <UITableBody
            {...(onClickRows && { onClickRows })}
            {...(onClickCol && { onClickCol })}
            content={content}
            tdSpan={tdSpan}
            classTd={classTd}
            vertical={vertical}
            header={header}
          />
        ) : (
          <UITableError textError={textError} header={header} />
        )}
      </tbody>
    </table>
  </UILoaderWrap>
);

export default UITable;
