import {
  CLEAR_REFRESH,
  CREATE_OFFICES_FAILURE,
  CREATE_OFFICES_REQUEST,
  CREATE_OFFICES_SUCCESS,
  CREATE_USERS_FAILURE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  GET_OFFICES_FAILURE,
  GET_OFFICES_REQUEST,
  GET_OFFICES_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SET_OFFICES_FAILURE,
  SET_OFFICES_REQUEST,
  SET_OFFICES_SUCCESS,
  SET_USERS_FAILURE,
  SET_USERS_REQUEST,
  SET_USERS_SUCCESS,
} from './Users.constant';

const initialState = {
  users: [],
  user: [],
  lastPage: null,
  offices: [],
  loading: false,
  loadingItems: false,
  refresh: false,
};

export default function UsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OFFICES_REQUEST:
      return {
        ...state,
        users: [],
        loadingItems: true,
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        offices: [],
        loadingItems: true,
      };
    case SET_OFFICES_REQUEST:
    case SET_USERS_REQUEST:
    case CREATE_USERS_REQUEST:
    case CREATE_OFFICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFICES_FAILURE:
    case GET_USERS_FAILURE:
      return {
        ...state,
        loadingItems: false,
      };
    case SET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        users: state.users.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }

          return i;
        }),
      };
    case SET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: state.offices.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }

          return i;
        }),
        loading: false,
      };
    case SET_OFFICES_FAILURE:
    case SET_USERS_FAILURE:
    case CREATE_OFFICES_FAILURE:
    case CREATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_OFFICES_SUCCESS:
    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        users: [],
        offices: [],
        refresh: true,
        loading: false,
      };
    case CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: [...state.users, ...action.payload.data],
        lastPage: action.payload.meta.last_page,
        loadingItems: false,
      };
    }
    case GET_OFFICES_SUCCESS: {
      return {
        ...state,
        offices: [...state.offices, ...action.payload.data],
        lastPage: action.payload.meta.last_page,
        loadingItems: false,
      };
    }
    default:
      return state;
  }
}
