import './style.scss';

import { LocalStorage } from '@services/storage';
import { useAppDispatch } from '@store/';
import { setStep } from '@store/ProjectAdd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const UIProjectAddStep = () => {
  const { projectData, managerData } = useSelector((state) => state.projectAdd);
  const { editData } = useSelector((state) => state.project);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handlerClick = (type) => {
    if (type === 0) {
      dispatch(setStep(0));
      LocalStorage.set('project_step', 0);
    }

    if (type === 1 && (projectData || editData)) {
      LocalStorage.set('project_step', 1);
      dispatch(setStep(1));
    }

    if (type === 2 && (managerData || !!editData?.place)) {
      dispatch(setStep(2));
      LocalStorage.set('project_step', 2);
    }
  };

  return (
    <div className="addEvent-step">
      <div className="active cursor_pointer" onClick={() => handlerClick(0)}>
        <span>1</span>
        {t('COMMON.MAIN')}
      </div>

      <span></span>

      <div
        className={cn('cursor_pointer', {
          active:
            +LocalStorage.get('project_step') === 1 ||
            +LocalStorage.get('project_step') === 2,
        })}
        onClick={() => handlerClick(1)}
      >
        <span>2</span>
        {t('COMMON.MANAGER')}
      </div>

      <span></span>

      <div
        className={cn('cursor_pointer', {
          active: +LocalStorage.get('project_step') === 2,
        })}
        onClick={() => handlerClick(2)}
      >
        <span>3</span>
        {t('COMMON.EQUIPMENT')}
      </div>
    </div>
  );
};

export default UIProjectAddStep;
