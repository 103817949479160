import { useEffect, useState } from 'react';

import { LocalStorage } from '@services/storage';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { CloseSVG } from '../../assets/img/icons/close.jsx';
import { languageOptions } from './constant';

const Language = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { i18n } = useTranslation();
  const handleLanguageChange = (type) => {
    LocalStorage.set('language', type);
    i18n.changeLanguage(type);
    setIsOpen(false);
  };

  useEffect(() => {
    if (LocalStorage.get('language')) {
      i18n.changeLanguage(LocalStorage.get('language'));
    }
  }, [i18n]);

  useEffect(() => {
    if (LocalStorage.get('language')) {
      i18n.changeLanguage(LocalStorage.get('language'));
    } else {
      LocalStorage.set('language', 'en');
    }
  }, [i18n]);

  return (
    <>
      <div className="language" onClick={() => setIsOpen(true)}>
        <span>
          {LocalStorage.get('language') ? LocalStorage.get('language') : 'en'}
        </span>
      </div>

      <div className={cn('language-popap', { 'language-popap-open': isOpen })}>
        <div className="language-popap-close" onClick={() => setIsOpen(false)}>
          <CloseSVG fill="#fff" />
        </div>

        {languageOptions.map((item, index) => (
          <img
            key={index}
            src={item.image}
            onClick={() => handleLanguageChange(item.id)}
            alt="flug"
          />
        ))}
      </div>
    </>
  );
};

export default Language;
